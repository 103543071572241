import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { FiledTitle } from './BaseComponent/FiledInput';
import { BasePageComponentProps, PageComponentConfigMap } from './BaseComponent/PageComponentMap';
import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';

type FeedbackSelectProps = BasePageComponentProps & PageComponentConfigMap['feedbackSelect'];
function FeedBackSelectBar(props: {
  feedbackMap: {
    label: string;
    labelCn: string;
    value: string;
  }[];

  item: {
    label: string;
    labelCn: string;
  };
  valueKey: string;
}) {
  const isZhHKLanguage = useIsZhHKLanguage();
  const { pageValues, setPageValue } = usePageContext();
  const valueLabel = pageValues[props.valueKey]?.value;

  const value = props.feedbackMap.find((f) => f.label === valueLabel)?.value || '';

  const onValueChange = (v: string) => {
    const realV = props.feedbackMap.find((f) => f.value === v)?.label || '';

    setPageValue(props.valueKey, realV);
  };

  const [valueValid, setValueValid] = useState(true);

  useAddCheckValidFunc(() => {
    if (!value) {
      setValueValid(false);
      return false;
    }
    setValueValid(true);

    return true;
  });

  const [t] = useTranslation();
  const isLg = useMediaQuery() === MEDIA_SIZE.LG_SIZE;

  return (
    <>
      <div className="md:flex items-center w-[100%] mt-[15px] md:mt-[15px]">
        <p className="md:w-[130px] text-[#25282b] mt-[20] md:mt-[30] md:text-[16px] text-[14px] leading-[20px] md:leading-[20px] font-[600]">
          {isZhHKLanguage ? props.item.labelCn : props.item.label}
        </p>
        <div className="flex">
          {props.feedbackMap.map((f) => {
            return (
              <div
                className={classNames(
                  'h-[38px] md:h-[38px] w-[120px] md:w-[100px] cursor-pointer',
                  'flex-1 md:flex-initial',
                  'border-[#009FAF] border-[1px] border-solid',
                  'rounded-[5px] md:rounded-[5px]',
                  'mx-[2px] md:mx-[10px]',
                  'flex items-center justify-center',
                  'text-[14px] leading-[20px] font-[500]',
                  'md:text-[14px] md:leading-[20px]',

                  valueValid && value === f.value && 'bg-[#2DBDB6] text-[#ffffff]',
                  valueValid && value !== f.value && 'bg-[#FFFFFF] text-[#009FAF]',
                  !valueValid && 'text-[#DD524C] bg-[#FFF3F2] border-[#DD524C]',
                )}
                key={f.value}
                onClick={() => {
                  setValueValid(true);
                  onValueChange(f.value);
                }}
              >
                {f.value}
              </div>
            );
          })}
        </div>
      </div>
      {!valueValid && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: isLg ? '11px' : '0.2683rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </>
  );
}

export default function FeedbackSelect(props: FeedbackSelectProps) {
  const isZhHKLanguage = useIsZhHKLanguage();

  return (
    <>
      <div className="flex flex-col w-[100%] mt-[29px] md:mt-[29px]">
        <FiledTitle title={isZhHKLanguage ? props.titleCn : props.title} />
        {props.feedbackConfigs.map((feedbackConfig) => {
          return (
            <Fragment key={feedbackConfig.title}>
              <p className="text-[#25282b] mt-[20px] md:mt-[30px] md:text-[16px] text-[14px] leading-[20px] md:leading-[20px] font-[500]">
                {isZhHKLanguage ? feedbackConfig.titleCn : feedbackConfig.title}
              </p>
              {feedbackConfig.items.map((item) => {
                const valueKey = `${feedbackConfig.title}(${item.label})`;

                return (
                  <FeedBackSelectBar
                    key={item.label}
                    valueKey={valueKey}
                    feedbackMap={feedbackConfig.feedbackMap}
                    item={item}
                  />
                );
              })}

              <div
                className="
                bg-[#DDEBEB] rounded-full
                  mt-[10px] md:mt-[10px] w-full md:w-fit px-[0] md:px-[34px]
                  py-[10px] md:py-[10px]
                  flex items-center
                  md:translate-x-[80px]
              "
              >
                {feedbackConfig.feedbackMap.map((f) => {
                  return (
                    <div
                      className="
                        w-[120px] md:w-[120px]
                        rounded-[5px] md:rounded-[5px]
                        flex items-center justify-center
                        text-[#25282B] text-[9px] leading-[16px] font-[700]
                        md:text-[11px] md:leading-[16px]
                        text-center whitespace-pre-wrap
                        "
                      key={f.value}
                    >
                      {isZhHKLanguage ? f.labelCn : f.label}
                    </div>
                  );
                })}
              </div>
            </Fragment>
          );
        })}
      </div>
    </>
  );
}
