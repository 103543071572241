import StepTitle from './StepTitle';

export function StepBox(props: { children: React.ReactNode; step: number }) {
  return (
    <div className="w-full h-full flex flex-col bg-[#f5f7fa]  min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] lg:w-[71.38vw] lg:m-auto  lg:bg-[#FFFFFF] lg:rounded-[8px] lg:shadow-[0_12px_26px_rgba(16,30,115,0.06)]">
      <StepTitle curPage={props.step} />

      <div className="p-[20px_25px_63px_25px] lg:p-[36px_9.86vw_64px_7.63vw]">{props.children}</div>
    </div>
  );
}
