import { MEDIA_SIZE } from '@consts/consts';
import CnFiledInput, { CnFiledInputPropsOnCheckValidType } from '@pages/CollectionBootle/components/CnFiledInput';
import CollectionDatePicker from '@pages/CollectionBootle/components/CollectionDatePicker';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';
import { isLowVersionChangeDatePicker } from '@utils/index';
import classNames from 'classnames';
import { t as _t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';

import dayjs from 'dayjs';
import DropOffLcaltion from './DropOffLocaltion';

const isLowVersion = isLowVersionChangeDatePicker();

const createCheckDateFunc = (htxt: string): CnFiledInputPropsOnCheckValidType => {
  return (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
    if (val === '' && !isFiledInputSubmitCheck) {
      setStatus(undefined);
      setHelpText('');
    } else if (val === '' && isFiledInputSubmitCheck) {
      setStatus('error');
      setHelpText(htxt);
    } else {
      setStatus('success');
      setHelpText('');
    }
  };
};

const dayValidFunc = createCheckDateFunc(_t('collectionBootle.DateNotBeEmpty'));
const monthValidFunc = createCheckDateFunc(_t('collectionBootle.MonthCanNotBeEmpty'));
const yearValidFunc = createCheckDateFunc(_t('collectionBootle.YearCanNotBeEmpty'));

export default function DropOffDate(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [dropOffDate, setDropOffDate] = useState<string | undefined>(formValue[-4] || undefined);
  const [dropOffDay, setDropOffDay] = useState<string>('');
  const [dropOffMonth, setDropOffMonth] = useState<string>('');
  const [dropOffYear, setDropOffYear] = useState<string>('');

  const isError = !dropOffDate && hasClickedNext;

  useEffect(() => {
    if (dropOffDay && dropOffMonth && dropOffYear) {
      setDropOffDate(`${dropOffYear}/${dropOffMonth}/${dropOffDay}`);
      setFormValue({
        [-4]: `${dropOffYear}/${dropOffMonth}/${dropOffDay}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropOffDay, dropOffMonth, dropOffYear]);

  useAddThirdStepCheckValidFunc(() => {
    if (!dropOffDate) {
      return false;
    }
    return true;
  });

  const SubmitMethod = formValue[19];
  useEffect(() => {
    if (SubmitMethod !== 'DropOff') {
      setDropOffDate(undefined);
      setDropOffDay('');
      setDropOffMonth('');
      setDropOffYear('');

      setFormValue({
        22: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SubmitMethod]);

  useAddThirdStepCheckValidFunc(() => {
    if (!dropOffDate) {
      return false;
    }
    return true;
  });

  if (formValue[19] !== 'DropOff') return null;

  return (
    <div className="flex flex-col">
      {/* TODO: drop off time 开始*/}
      {isLowVersion ? (
        <div className="flex flex-col mb-[29px] lg:mb-[45px]">
          <FiledTitle title={t('collectionBootle.questionMinus4')} />
          <div className="flex justify-between">
            <CnFiledInput
              style={{
                width: '30%',
                margin: isLg ? '0px' : '0px',
              }}
              value={dropOffDay}
              onChange={setDropOffDay}
              placeholder={t('collectionBootle.DatePlaceHolder')}
              onCheckValid={dayValidFunc}
              isSubmitCheck={!hasClickedNext}
              number={true}
            />
            <CnFiledInput
              style={{
                width: '30%',
                margin: isLg ? '0px' : '0px',
              }}
              value={dropOffMonth}
              onChange={setDropOffMonth}
              placeholder={t('collectionBootle.MonthPlaceHolder')}
              onCheckValid={monthValidFunc}
              isSubmitCheck={!hasClickedNext}
              number={true}
            />
            <CnFiledInput
              style={{
                width: '30%',
                margin: isLg ? '0px' : '0px',
              }}
              value={dropOffYear}
              onChange={setDropOffYear}
              placeholder={t('collectionBootle.YearPlaceHolder')}
              onCheckValid={yearValidFunc}
              isSubmitCheck={!hasClickedNext}
              number={true}
            />
          </div>
        </div>
      ) : (
        <CollectionDatePicker
          title={t('collectionBootle.questionMinus4') as string}
          onChange={(_, dateString) => {
            setDropOffDate(dateString);
            setFormValue({
              [-4]: dateString,
            });
          }}
          placeholder={t('collectionBootle.question6Placeholder') as string}
          defaultValue={dropOffDate}
          forceDisabledDate={true}
          collectionDatePickerClassName={classNames(
            isError && 'my-select-selector-empty-type',
            'mb-[29px] lg:mb-[45px]',
          )}
          disabledStartDate={dayjs().subtract(1, 'day').format('YYYY/MM/DD')}
          disabledEndDate={dayjs('2100/01/01').format('YYYY/MM/DD')}
          isDropOffTime={true}
        />
      )}

      {isError && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: isLg ? '11px' : '0.2683rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
      {/* TODO: drop off time 结束 */}

      {dropOffDate && <DropOffLcaltion hasClickedNext={hasClickedNext} dropOffDate={dropOffDate} />}
    </div>
  );
}
