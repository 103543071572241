import { useMemoizedFn } from 'ahooks';
import React, { useEffect } from 'react';

export type CheckValidFunc = () => boolean | Promise<boolean>;
type CancelFunc = () => void;
const defaultCtxValue = {
  checkValidFuncs: [],
  addCheckValidFunc: () => {
    return () => {};
  },
};

export const createStepContext = () => {
  return React.createContext<{
    checkValidFuncs: CheckValidFunc[];
    addCheckValidFunc: (func: CheckValidFunc) => CancelFunc;
  }>(defaultCtxValue);
};

export const FirstStepContext = createStepContext();
export const SecondStepContext = createStepContext();
export const ThirdStepContext = createStepContext();

export const useFirstStepContext = () => {
  return React.useContext(FirstStepContext);
};

export const useSecondStepContext = () => {
  return React.useContext(SecondStepContext);
};

export const useThirdStepContext = () => {
  return React.useContext(ThirdStepContext);
};

export const useAddSecondStepCheckValidFunc = (func: () => boolean) => {
  const { addCheckValidFunc } = useSecondStepContext();
  const memoFunc = useMemoizedFn(func);
  useEffect(() => {
    return addCheckValidFunc(memoFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useAddThirdStepCheckValidFunc = (func: () => boolean) => {
  const { addCheckValidFunc } = useThirdStepContext();
  const memoFunc = useMemoizedFn(func);
  useEffect(() => {
    return addCheckValidFunc(memoFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
