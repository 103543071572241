import { MEDIA_SIZE } from '@consts/consts';
import { HiddenDayType } from '@consts/enums';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { renderDropOffList } from '@pages/CollectionBootle/renderFuncs/renderDropOffList';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';
import { isPublicHoliday, getSpecifyTimeWeekDay } from '@utils/index';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';

const hiddenLocationTime = (DODate: string, hiddenArr: number[]) => {
  if (!DODate) {
    return false;
  }

  // TODO: 这两个函数应该要调整一下
  if (isPublicHoliday(dayjs(DODate)) && hiddenArr.indexOf(HiddenDayType.PublicHoliday) !== -1) {
    return true;
  }

  if (hiddenArr.indexOf(getSpecifyTimeWeekDay(dayjs(DODate))) !== -1) {
    return true;
  }

  return false;
};

export default function DropOffLcaltion(props: { dropOffDate: string | undefined; hasClickedNext: boolean }) {
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [dropLocation, setDropLocation] = useState(formValue[22] || undefined);
  const [dropLocationCn, setDropLocationCn] = useState<string>();
  useEffect(() => {
    localStorage.setItem('dropLocationCn', dropLocationCn || '');
  }, [dropLocationCn]);

  useAddThirdStepCheckValidFunc(() => {
    if (!dropLocation) {
      return false;
    }
    return true;
  });
  const { dropOffDate, hasClickedNext } = props;

  const isError = !dropOffDate && hasClickedNext;
  const forceThirdSubmitShowError = useMemoizedFn(() => {
    return isError;
  });

  if (!dropOffDate) return null;
  return (
    <>
      <FiledTitle title={t('collectionBootle.question17')} />
      {renderDropOffList({
        dropOffDate,
        dropLocation,
        isLg,
        setFormValue,
        formValue,
        t,
        setDropLocation,
        setDropLocationCn,
        forceThirdSubmitShowError,
        hiddenLocationTime,
      })}
      {isError && !dropOffDate && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: isLg ? '0px' : '0px',
            position: 'relative',
            top: isLg ? '-15px' : '-0.3659rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
      <p
        className="font-[600] text-[16px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[20px] flex lg:leading-[24px] lg:tracking-[0.4px] "
        style={{
          marginTop: isLg ? '45px' : '0.7073rem',
          marginBottom: isLg ? '0px' : '0rem',
        }}
      >
        {t('collectionBootle.question17InfoTitle')}
      </p>
      <FiledTitle
        title={t('collectionBootle.question17InfoText')}
        style={{
          marginTop: isLg ? '29px' : '0.7073rem',
          marginBottom: isLg ? '0px' : '0rem',
        }}
      />
    </>
  );
}
