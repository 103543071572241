import earth from '@images/greenEarth.svg';
import logo from '@images/INDICAIDLogo.png';
import React, { useState, useRef, Fragment } from 'react';
import { Empty } from 'antd';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import { switchLanguage } from '@utils/index';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { zhHKLanguage, enLanguage, useLocalNavigate } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';
import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import { CompaginConfig } from './BaseComponent/ConfigPage.type';
import FullNameInput from './FullNameInput';
import AgreeCheckbox from './AgreeCheckBox';
import { usePageContext } from './BaseComponent/formContext';
import { useParams } from 'react-router-dom';

const Agree = (props: CompaginConfig['agreement']) => {
  const [t] = useTranslation();
  const defaultFontStyle = useGetFontStyle();
  const isZhHKLanguage = useIsZhHKLanguage();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [, setIsReachedBottom] = useState(false);
  const [isCodeError] = useState(false);
  const [disagreeTick4BoxValue] = useState(false);
  const [disagreeTick5BoxValue] = useState(false);
  const [disagreeTick6BoxValue] = useState(false);
  const [isLoading] = useState(false);

  console.log(disagreeTick4BoxValue, disagreeTick5BoxValue, disagreeTick6BoxValue);

  const ref = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleScroll = () => {
    // const { scrollHeight, clientHeight, scrollTop } = ref.current;
    if (ref?.current && ref?.current?.scrollHeight - ref?.current?.clientHeight <= ref?.current?.scrollTop + 1) {
      setIsReachedBottom(true);
    }
  };

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };

  const { checkValidFuncs } = usePageContext();

  const { compaginId, collectionBottleId } = useParams();
  const navigate = useLocalNavigate();

  const onClickAgree = async () => {
    const allRes = checkValidFuncs.map((func) => func());
    const finalRes = await Promise.all(allRes);

    if (finalRes.every((res) => res)) {
      navigate(`/compagin/${compaginId}/1/${collectionBottleId}`);
    }
  };

  function renderContent(_content: string) {
    return (
      <>
        {_content.split('\n').map((s, idx) => {
          return (
            <Fragment key={idx}>
              {s}
              <br />
            </Fragment>
          );
        })}
      </>
    );
  }

  return (
    <div className="min-h-[100vh] bg-[#f5f7fa] flex-col">
      <div
        className="w-full h-full flex flex-col min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] bg-[#f5f7fa]"
        // style={style.container}
      >
        <div className="flex p-[42px_25px_28px_34px] justify-between items-center  lg:w-[63.68vw] lg:mx-[auto] lg:pl-[0px] lg:px-[0.55vw] lg:pt-[45px] lg:pb-[52px]">
          <div className="w-[180px] lg:w-[180px]">
            <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer  lg:w-[180px]" />
          </div>
          {isLg ? (
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={() => {
                onClickLanguage();
              }}
            >
              <div className="w-[24px]  lg:w-[29px]">
                <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
              </div>
              <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
                {t('header.language')}
              </div>
            </div>
          ) : (
            <div
              className="w-[24px]  lg:w-[29px] cursor-pointer"
              onClick={() => {
                onClickLanguage();
              }}
            >
              <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
            </div>
          )}
        </div>
        {isCodeError ? (
          <div className="justify-center items-center flex w-[100vw] h-[70vh]">
            <Empty
              description={
                <div
                  className="font-[600] text-[#e81022] text-[22px] leading-[36px]   text-center"
                  style={{
                    fontFamily: defaultFontStyle,
                  }}
                >
                  {t('agreenments.codeErrorMessage')}
                </div>
              }
            />
          </div>
        ) : (
          <div className="px-[34px] lg:w-[63.68vw] lg:mx-[auto] lg:px-[0px]">
            <div className="bg-[#DDEBEB] rounded-[10px] shadow-[0_10px_30px_rgba(171,190,209,0.2)] scroll-auto mx-auto  lg:rounded-[10px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)]">
              <div className="font-[700] text-[#212121] text-[22px] leading-[36px] py-[20px]  text-center lg:text-[28px] lg:leading-[36px] lg:font-[700] lg:pl-[65px] lg:pr-[63px]">
                {isZhHKLanguage ? props?.titleCn : props?.title}
              </div>
              <div className="flex flex-col  bg-[#FFFFFF]">
                {/* 内容部分 */}
                <div
                  className="px-[17.5px] pb-[20px] font-[400] text-[#000000] text-[14px] leading-[28px]  scroll-auto h-[70vh] overflow-y-auto agreements-scroll lg:pl-[65px] lg:pr-[63px] lg:text-[14px] lg:leading-[28px] lg:h-[70vh] pt-[20px]"
                  ref={ref}
                  onScroll={handleScroll}
                >
                  {props?.contentList.map((c, idx) => {
                    if (c.type === 'text') {
                      return <Fragment key={idx}>{renderContent(isZhHKLanguage ? c.contentCn : c.content)}</Fragment>;
                    } else if (c.type === 'checkbox') {
                      return <AgreeCheckbox key={idx} content={c.content} contentCn={c.contentCn} />;
                    } else if (c.type === 'name-input') {
                      return <FullNameInput key={idx} />;
                    }

                    return <Fragment key={idx}></Fragment>;
                  })}
                </div>
              </div>
              <div className="p-[18px_10px_22px_10px] lg:p-[24px_0px_28px_0px] lg:flex lg:justify-center lg:items-center">
                <CollectionBootleButton
                  htmlType="submit"
                  buttonClassName="w-[100%] h-[41px!important] md:w-[326px] md:h-[41px!important] md:text-[16px] md:mt-[0px] md:mb-[0px]"
                  value={t('agreenments.Agree')}
                  status={'default'}
                  onClick={() => {
                    onClickAgree();
                  }}
                  loading={isLoading}
                ></CollectionBootleButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Agree;
