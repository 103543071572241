import { DISAGREE_FIRST_POSITION, MEDIA_SIZE } from '@consts/consts';
import BaseTextArea from '@pages/CollectionBootle/components/BaseTextArea';
import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import enLocales from '@locales/en.json';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';

const whereList = [
  { value: enLocales.collectionBootle.question24Select1, label: 'collectionBootle.question24Select1' },
  { value: enLocales.collectionBootle.question24Select2, label: 'collectionBootle.question24Select2' },
  { value: enLocales.collectionBootle.question24Select3, label: 'collectionBootle.question24Select3' },
  { value: enLocales.collectionBootle.question24Select4, label: 'collectionBootle.question24Select4' },
  { value: enLocales.collectionBootle.question24Select5, label: 'collectionBootle.question24Select5' },
  { value: enLocales.collectionBootle.question24Select6, label: 'collectionBootle.question24Select6' },
  { value: enLocales.collectionBootle.question24Select7, label: 'collectionBootle.question24Select7' },
  { value: enLocales.collectionBootle.question24Select13, label: 'collectionBootle.question24Select13' },
];

const getInitValue = (vArr: string[]) => {
  return vArr.filter((item) => whereList.find((x) => x.value === item));
};

const getInitValue2 = (vArr: string[]) => {
  const idx = vArr.findIndex((item) => !whereList.find((x) => x.value === item));
  if (idx === -1) {
    return '';
  }
  return vArr[idx];
};

export default function OtherInfo2(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [whyPurchase, setWhyPurchase] = useState<string[]>(
    getInitValue((formValue[DISAGREE_FIRST_POSITION - 6] as string[]) || []),
  );
  const [whyPurchaseTextArea, setWhyPurchaseTextArea] = useState<string>(
    getInitValue2((formValue[DISAGREE_FIRST_POSITION - 6] as string[]) || []),
  );
  useAddThirdStepCheckValidFunc(() => {
    if (whyPurchase.includes(enLocales.collectionBootle.question24Select13)) {
      return whyPurchaseTextArea !== '';
    }
    return whyPurchase.length > 1;
  });

  const isWhereError = whyPurchase.length < 2 && hasClickedNext;
  return (
    <>
      {/* Where did you learn about the INDICAID HPV Urine Test? */}

      <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[45px]">
        <FiledTitle title={`${t('collectionBootle.question24')}`} />

        {whereList.map((item) => {
          return (
            <CollectionBootleButton
              key={item.value}
              style={{
                height: isLg ? '90px' : '1.9024rem',
                whiteSpace: 'pre-wrap',
              }}
              htmlType="submit"
              buttonClassName="w-full mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
              value={t(item.label)}
              status={whyPurchase.includes(item.value) ? 'check' : isWhereError ? 'emptyError' : 'unchecked'}
              onClick={() => {
                setWhyPurchase((prev) => {
                  let newValueArr: string[] = [];
                  if (prev.includes(item.value)) {
                    newValueArr = prev.filter((x) => x !== item.value);
                  } else {
                    newValueArr = [...prev, item.value];
                  }
                  if (item.value === enLocales.collectionBootle.question24Select13) {
                    return newValueArr;
                  }
                  setFormValue({
                    [DISAGREE_FIRST_POSITION - 6]: newValueArr,
                  });
                  return newValueArr;
                });
              }}
            ></CollectionBootleButton>
          );
        })}

        {whyPurchase.includes(enLocales.collectionBootle.question24Select13) && (
          <div className="font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px]  mb-[18px]  lg:text-[14px] lg:leading-[20px] lg:mt-[0px]  lg:mb-[26px]">
            {t('collectionBootle.PleaseSpecify')}
          </div>
        )}
        {whyPurchase.includes(enLocales.collectionBootle.question24Select13) && (
          <BaseTextArea
            placeholder={t('collectionBootle.EnterTypeMessage') as string}
            defaultValue={whyPurchaseTextArea}
            onChange={(e) => {
              const v = e.target.value;

              setWhyPurchaseTextArea(v);
              const newValueArr = [...whyPurchase.filter((x) => x !== enLocales.collectionBootle.question24Select13)];
              if (v) {
                newValueArr.push(v);
              }
              setFormValue({
                [DISAGREE_FIRST_POSITION - 6]: newValueArr,
              });
            }}
            collectionDatePickerClassName={classNames(isWhereError && 'my-select-selector-empty-type')}
          ></BaseTextArea>
        )}
        {isWhereError && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              marginTop: isLg ? '11px' : '0.2683rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </div>
    </>
  );
}
