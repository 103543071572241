import { useTranslation } from 'react-i18next';

export default function StepTitle(props: { curPage: number }) {
  const [t] = useTranslation();

  const renderTitleText = () => {
    if (props.curPage === 1) {
      return `1. ${t('collectionBootle.title')}`;
    } else if (props.curPage === 2) {
      return `2. ${t('collectionBootle.progress2')}`;
    } else {
      return `3. ${t('collectionBootle.progress3')}`;
    }
  };
  return (
    <div className="h-[104px] flex items-center px-[25px] justify-between bg-[#2DBDB6] lg:h-[105px]  lg:px-[7.56vw]">
      <div className="font-[600] text-[#FFFFFF] text-[20px] leading-[24px] w-[100%] lg:w-[100%] lg:text-[28px] lg:leading-[24px] ">
        {renderTitleText()}
      </div>
    </div>
  );
}
