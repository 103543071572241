import MessageText from '@pages/CollectionBootle/components/MessageText';
import { Select } from 'antd';
import classNames from 'classnames';
import { FiledTitle } from './BaseComponent/FiledInput';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import dayjs from 'dayjs';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import { BasePageComponentProps, PageComponentConfigMap } from './BaseComponent/PageComponentMap';
import { useMemoizedFn } from 'ahooks';

const isEmptyValue = (value: any) => {
  if (!value || (value && value.length === 0) || value === '') {
    return true;
  } else {
    return false;
  }
};
type BirthdaySelectProps = BasePageComponentProps & PageComponentConfigMap['birthday'];
export default function BirthdaySelect(props: BirthdaySelectProps) {
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [isDayError, setIsDayError] = useState(false);
  const [isMonthError, setIsMonthError] = useState(false);
  const [isYearError, setIsYearError] = useState(false);

  const [dobDay, setDobDay] = useState<string | undefined>(undefined);
  const [dobMonth, setDobMonth] = useState<string | undefined>(undefined);
  const [dobYear, setDobYear] = useState<string | undefined>('1994');

  const { pageValues, setPageValue } = usePageContext();

  const onValueChange = (v: string) => {
    setPageValue(props.title, v);
  };
  const onValueChangeMemo = useMemoizedFn(onValueChange);
  const currentValue = pageValues[props.title]?.value;
  useEffect(() => {
    if (!currentValue) {
      return;
    }
    const [year, month, day] = currentValue.split('-');
    setDobDay(day);
    setDobMonth(month);
    setDobYear(year);
  }, [currentValue]);

  useEffect(() => {
    if (isEmptyValue(dobDay) || isEmptyValue(dobMonth) || isEmptyValue(dobYear)) {
      return;
    }

    onValueChangeMemo(`${dobYear}-${dobMonth}-${dobDay}`);
  }, [dobDay, dobMonth, dobYear, onValueChangeMemo]);

  const yearOptions = useMemo(() => {
    const options = [];
    for (let year = 1900; year <= dayjs().year(); year++) {
      options.push({
        label: year.toString(),
        value: year.toString(),
      });
    }
    return options;
  }, []);

  const monthOptions = useMemo(() => {
    const options = [];
    for (let month = 1; month <= 12; month++) {
      const monthString = month.toString().padStart(2, '0');
      options.push({
        label: monthString,
        value: monthString,
      });
    }
    return options;
  }, []);

  const dayOptions = useMemo(() => {
    const options = [];
    let limitDay = 31;
    if (!isEmptyValue(dobMonth) && !isEmptyValue(dobYear)) {
      limitDay = new Date(Number(dobYear), Number(dobMonth), 0).getDate();
    }
    for (let day = 1; day <= limitDay; day++) {
      // 填充到长度2
      const dayString = day.toString().padStart(2, '0');
      options.push({
        label: dayString,
        value: dayString,
      });
    }
    return options;
  }, [dobMonth, dobYear]);

  useAddCheckValidFunc(() => {
    const isDayEmpty = isEmptyValue(dobDay);
    const isMonthEmpty = isEmptyValue(dobMonth);
    const isYearEmpty = isEmptyValue(dobYear);
    if (isDayEmpty) {
      setIsDayError(true);
    }
    if (isMonthEmpty) {
      setIsMonthError(true);
    }
    if (isYearEmpty) {
      setIsYearError(true);
    }
    return !isDayEmpty && !isMonthEmpty && !isYearEmpty;
  });

  return (
    <div className="flex flex-col  mt-[40px] lg:mt-[50px]">
      <FiledTitle title={t('collectionBootle.question6')} />
      <div className="flex justify-between">
        <div
          className={classNames(
            isDayError && 'my-select-selector-empty-type',
            'lg:w-[30%] m-[0px] w-[30%] flex flex-col mt-[0px] mb-[0px] lg:mt-[0px] lg:mb-[0px]',
          )}
        >
          <Select
            value={dobDay || undefined}
            onChange={(v) => {
              setDobDay(v);
              setIsDayError(false);
            }}
            style={{
              height: isLg ? '48px' : '1.1707rem',
              color: '#8B849B',
              fontWeight: 400,
              fontSize: isLg ? '14px' : '0.3415rem',
              lineHeight: isLg ? '24px' : '0.5854rem',
            }}
            placeholder={t('collectionBootle.DatePlaceHolder') as string}
            defaultValue={dobDay || undefined}
            // inputReadOnly={true}
            options={dayOptions}
          ></Select>
          {isDayError && (
            <MessageText
              helpText={t('collectionBootle.fieldsRequiredMessage') as string}
              status={'error'}
              style={{
                marginTop: isLg ? '0px' : '0rem',
              }}
              innerStyle={{
                justifyContent: 'flex-start',
                marginBottom: '0px',
              }}
            ></MessageText>
          )}
        </div>
        <div
          className={classNames(
            isMonthError && 'my-select-selector-empty-type',
            'lg:w-[30%] m-[0px] w-[30%] flex flex-col mt-[0px] mb-[0px] lg:mt-[0px] lg:mb-[0px]',
          )}
        >
          <Select
            value={dobMonth || undefined}
            onChange={(v) => {
              setDobMonth(v);
              setIsMonthError(false);
            }}
            style={{
              height: isLg ? '48px' : '1.1707rem',
              color: '#8B849B',
              fontWeight: 400,
              fontSize: isLg ? '14px' : '0.3415rem',
              lineHeight: isLg ? '24px' : '0.5854rem',
            }}
            placeholder={t('collectionBootle.MonthPlaceHolder') as string}
            defaultValue={dobMonth || undefined}
            // inputReadOnly={true}
            options={monthOptions}
          ></Select>
          {isMonthError && (
            <MessageText
              helpText={t('collectionBootle.fieldsRequiredMessage') as string}
              status={'error'}
              style={{
                marginTop: isLg ? '0px' : '0rem',
              }}
              innerStyle={{
                justifyContent: 'flex-start',
                marginBottom: '0px',
              }}
            ></MessageText>
          )}
        </div>
        <div
          className={classNames(
            isYearError && 'my-select-selector-empty-type',
            'lg:w-[30%] m-[0px] w-[30%] flex flex-col mt-[0px] mb-[0px] lg:mt-[0px] lg:mb-[0px]',
          )}
        >
          <Select
            value={dobYear || undefined}
            onChange={(v) => {
              setDobYear(v);
              setIsYearError(false);
            }}
            style={{
              height: isLg ? '48px' : '1.1707rem',
              color: '#8B849B',
              fontWeight: 400,
              fontSize: isLg ? '14px' : '0.3415rem',
              lineHeight: isLg ? '24px' : '0.5854rem',
            }}
            placeholder={t('collectionBootle.YearPlaceHolder') as string}
            defaultValue={dobYear || undefined}
            // inputReadOnly={true}
            options={yearOptions}
          ></Select>
          {isYearError && (
            <MessageText
              helpText={t('collectionBootle.fieldsRequiredMessage') as string}
              status={'error'}
              style={{
                marginTop: isLg ? '0px' : '0rem',
              }}
              innerStyle={{
                justifyContent: 'flex-start',
                marginBottom: '0px',
              }}
            ></MessageText>
          )}
        </div>
      </div>
    </div>
  );
}
