/* eslint-disable @typescript-eslint/indent */

// TODO 需要做的需求
// ①loading状态
// ②获取校验码，=>校验成功之后，跳转至人机校验页面
// ③看看如何进行人机校验
// const style = {
//   container: {
//     background: '#FDFDFD',
//   },
// };
import earth from '@images/greenEarth.svg';
import logo from '@images/INDICAIDLogo.png';
import './agreenments.css';
import CollectionBootleButton from '../CollectionBootle/components/CollectionBootleButton';
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalNavigate } from 'src/router/routerTools';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website.json';
import { Empty } from 'antd';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import { switchLanguage } from '@utils/index';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import { zhHKLanguage, enLanguage } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useMemoizedFn } from 'ahooks';
import { DisagreeType } from '@consts/enums';
import { jsPDF } from 'jspdf';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import { message } from 'antd';
import { get } from '@utils/request';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';
import { newVersion } from '@utils/domains';
import i18n from '@utils/i18n';
import { useSetFormValue } from '@states/formValue.state';
import { getSampleStatus } from '@services/productExpired/productExpired.service';
import { setSampleExpireDate } from '@pages/CollectionBootle/tool';
import ChinesePart from './ChinesePart';
import EnglishPart from './EnglishPart';

const Agreements: React.FC = () => {
  const isEn = i18n.language === enLanguage;
  const [t] = useTranslation();
  const defaultFontStyle = useGetFontStyle();
  const isZhHKLanguage = useIsZhHKLanguage();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const collectionBootleId = useParams().collectionBootleId;
  const [, setIsReachedBottom] = useState(false);
  const [isCodeError, setIsCodeError] = useState(true);
  const [disagreeTick4BoxValue, setDisagreeTick4BoxValue] = useState(false);
  const [disagreeTick5BoxValue, setDisagreeTick5BoxValue] = useState(false);
  const [disagreeTick6BoxValue, setDisagreeTick6BoxValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const code = query.get('c');

  const headingStyle = {
    fontWeight: isZhHKLanguage ? '500' : '600',
  };

  const ref = useRef<HTMLDivElement>(null);
  const navigate = useLocalNavigate();

  const onDisagreeTick4BoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setDisagreeTick4BoxValue(e.target.checked);
  });

  const onDisagreeTick5BoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setDisagreeTick5BoxValue(e.target.checked);
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDisagreeTick6BoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setDisagreeTick6BoxValue(e.target.checked);
  });

  const handleScroll = () => {
    // const { scrollHeight, clientHeight, scrollTop } = ref.current;
    if (ref?.current && ref?.current?.scrollHeight - ref?.current?.clientHeight <= ref?.current?.scrollTop + 1) {
      setIsReachedBottom(true);
    }
  };

  const onClickLanguage = () => {
    const origin = isZhHKLanguage ? zhHKLanguage : enLanguage;
    // 切换为另外一个语言
    const target = isZhHKLanguage ? enLanguage : zhHKLanguage;
    switchLanguage(origin, target);
  };

  const downloadPdf = () => {
    // Default export is a4 paper, portrait, using millimeters for units
    let doc = new jsPDF();

    // 设置字体样式
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');

    // 添加多行文本
    // var text =
    //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.';

    let text =
      'statement5\n\nI know that the Test does not constitute a medical/clinical diagnosis, and it is not intended to be a medical service. Due to environmental factors and other variables, I know that even if the presence of HPV is detected, the test result does not mean that he/she will inevitably develop certain physical conditions or diseases. Conversely, in the case that HPV is not detected, the test result does not constitute any guarantee or assurance that the person will not develop those physical conditions or diseases.\n \nstatement6\n\nI understand that I cannot make any personal or medical decisions solely based on the test result(s) or reports(s) of the Test. I understand and agree that PHASE Scientific will not be responsible for any decision(s), whether medical or not, made by me by relying on the test result(s) or report(s) of the Test.';

    let textLines = doc.splitTextToSize(text, 180); // 分割成多行
    doc.text(textLines, 10, 10, { align: 'left', lineHeightFactor: 1.2 });
    doc.save('agreementStatement.pdf');
  };

  const setFormValue = useSetFormValue();

  const onClickAgree = async () => {
    // collectionBootleId
    setIsLoading(true);
    try {
      let checkPath = '/hpv/isSampleIdExist';
      if (newVersion.find((v) => window.location.origin.includes(v))) {
        checkPath = '/hpv/isSampleIdExistV2';
      }

      const beValue = await get(checkPath, {
        sampleId: collectionBootleId,
      });

      if (beValue?.isExist === true) {
        message.error(t('agreenments.IdErrorMessage'));
        setIsLoading(false);
        return;
      }
      handleLocalStorage.set(website.is_sign_agreements, true);
      if (collectionBootleId) {
        handleLocalStorage.set(website.collection_bootle_id, collectionBootleId);
      }
      setIsLoading(false);
      setFormValue({
        [-3]: disagreeTick4BoxValue ? 'disagreeAbove' : 'agreeAbove',
        [-2]: disagreeTick5BoxValue ? 'disagreeAbove' : 'agreeAbove',
        [-1]: disagreeTick6BoxValue ? 'disagreeAbove' : 'agreeAbove',
        [0]: `'${collectionBootleId}'`,
      });

      if (code) {
        navigate(`/firstStep/${collectionBootleId}`);
      } else {
        navigate(
          `/collectionBottle?d4=${disagreeTick4BoxValue ? DisagreeType.True : DisagreeType.False}&d5=${
            disagreeTick5BoxValue ? DisagreeType.True : DisagreeType.False
          }`,
        );
      }
    } catch (err: any) {
      const responseMessage = err?.response?.data?.message;
      const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
      message.error(errorMessage);
      setIsLoading(false);
      // gotoAgreement();
    }
  };

  useEffect(() => {
    const checkCode = () => {
      if (newVersion.find((v) => window.location.origin.includes(v))) {
        setIsCodeError(false);
        if (!collectionBootleId) {
          setIsCodeError(true);
          return;
        }
        handleLocalStorage.set(website.collection_bootle_id, collectionBootleId);

        return;
      }
      if (!collectionBootleId) {
        setIsCodeError(true);
        return;
      } else if (collectionBootleId && collectionBootleId.length !== 14) {
        setIsCodeError(true);
        return;
      }

      const lastValue = collectionBootleId[12].toString() + collectionBootleId[13].toString();

      const total =
        parseInt(collectionBootleId[4]) +
        parseInt(collectionBootleId[5]) +
        parseInt(collectionBootleId[6]) +
        parseInt(collectionBootleId[7]) +
        parseInt(collectionBootleId[8]) +
        parseInt(collectionBootleId[9]) +
        parseInt(collectionBootleId[10]) +
        parseInt(collectionBootleId[11]);

      const result = (99 - total).toString();
      if (result === lastValue) {
        setIsCodeError(false);
        handleLocalStorage.set(website.collection_bootle_id, collectionBootleId);
      } else {
        setIsCodeError(true);
      }
    };
    checkCode();
  }, [collectionBootleId]);

  const isHpvStudy = collectionBootleId?.startsWith('0113');
  const baseNumber = isHpvStudy ? 4 : 5;

  useEffect(() => {
    localStorage.removeItem('sampleExpiredDate');

    collectionBootleId &&
      getSampleStatus(collectionBootleId).then((r) => {
        if (r.isExpired) {
          navigate('/productExpired');
        }
        setSampleExpireDate(r.expiredDate || '');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionBootleId]);

  return (
    <div className="min-h-[100vh] bg-[#f5f7fa] flex-col">
      <div
        className="w-full h-full flex flex-col min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] bg-[#f5f7fa]"
        // style={style.container}
      >
        <div className="flex p-[42px_25px_28px_34px] justify-between items-center  lg:w-[63.68vw] lg:mx-[auto] lg:pl-[0px] lg:px-[0.55vw] lg:pt-[45px] lg:pb-[52px]">
          <div className="w-[180px] lg:w-[180px]">
            <img src={logo} alt="logo" className="object-cover w-[180px]  cursor-pointer  lg:w-[180px]" />
          </div>
          {isLg ? (
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={() => {
                onClickLanguage();
              }}
            >
              <div className="w-[24px]  lg:w-[29px]">
                <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
              </div>
              <div className="lg:ml-[10px] font-[600] text-[#2DBDB6] lg:text-[18px] lg:leading-[18px]  text-center">
                {t('header.language')}
              </div>
            </div>
          ) : (
            <div
              className="w-[24px]  lg:w-[29px] cursor-pointer"
              onClick={() => {
                onClickLanguage();
              }}
            >
              <img src={earth} alt="earth" className="object-cover w-[24px]  cursor-pointer  lg:w-[29px]" />
            </div>
          )}
        </div>
        {isCodeError ? (
          <div className="justify-center items-center flex w-[100vw] h-[70vh]">
            <Empty
              description={
                <div
                  className="font-[600] text-[#e81022] text-[22px] leading-[36px]   text-center"
                  style={{
                    fontFamily: defaultFontStyle,
                  }}
                >
                  {t('agreenments.codeErrorMessage')}
                </div>
              }
            />
          </div>
        ) : (
          <div className="px-[34px] lg:w-[63.68vw] lg:mx-[auto] lg:px-[0px]">
            <div className="bg-[#DDEBEB] rounded-[10px] shadow-[0_10px_30px_rgba(171,190,209,0.2)] scroll-auto mx-auto  lg:rounded-[10px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)]">
              <div className="flex flex-col  bg-[#FFFFFF]">
                {/* 头部 */}
                <div className="flex flex-col pt-[31px] pb-[18px] lg:pt-[31px] lg:pb-[22px] lg:justify-center relative">
                  <div
                    className="absolute cursor-pointer right-[0px] top-[0px] lg:right-[8px] lg:top-[30px]"
                    style={{
                      display: 'none',
                    }}
                    onClick={() => {
                      downloadPdf();
                    }}
                  >
                    <DownloadOutlined
                      className="active:text-[#3c4148] active:bg-[#eaf3f3]"
                      style={{
                        color: '#09121F',
                        fontSize: isLg ? '30px' : '16px',
                      }}
                    />
                  </div>
                  <div className="font-[700] text-[#212121] text-[22px] leading-[36px]   text-center lg:text-[28px] lg:leading-[36px] lg:font-[700] lg:pl-[65px] lg:pr-[63px]">
                    {!!code ? t('newAgreementsHeader') : t('agreenments.RequisitionandConsentForm')}
                  </div>
                  {/* <div className="font-[400] text-[#212121] text-[22px] leading-[42px] text-center lg:font-[600] lg:text-[28px] lg:leading-[36px]">
                    Requisition and Consent Form
                  </div> */}
                </div>
                {/* 内容部分 */}
                <div
                  className="px-[17.5px] font-[400] text-[#000000] text-[14px] leading-[28px]  scroll-auto h-[45vh] overflow-y-auto agreements-scroll lg:pl-[65px] lg:pr-[63px] lg:text-[14px] lg:leading-[28px] lg:h-[50vh]"
                  ref={ref}
                  onScroll={handleScroll}
                >
                  {!!code && (
                    <>
                      {isEn ? (
                        <EnglishPart>
                          <BaseCheckbox
                            onChange={onDisagreeTick5BoxChange}
                            style={{
                              marginRight: isLg ? '16px' : '0.3902rem',
                              marginTop: isLg ? '0px' : undefined,
                            }}
                            checked={disagreeTick5BoxValue}
                          />
                        </EnglishPart>
                      ) : (
                        <ChinesePart>
                          <BaseCheckbox
                            onChange={onDisagreeTick5BoxChange}
                            style={{
                              marginRight: isLg ? '16px' : '0.3902rem',
                              marginTop: isLg ? '0px' : undefined,
                            }}
                            checked={disagreeTick5BoxValue}
                          />
                        </ChinesePart>
                      )}
                    </>
                  )}

                  {!code && (
                    <>
                      <span className="" style={headingStyle}>
                        {t('consentForm.Disclaimer')}
                      </span>
                      <br></br>
                      {t('consentForm.Disclaimer1')}
                      <br></br>
                      {t('consentForm.Disclaimer2')}
                      <br></br>
                      <br></br>
                      <span className="" style={headingStyle}>
                        {t('consentForm.PatientDeclaration')}
                      </span>
                      <br></br>
                      {t('consentForm.declarationText')}
                      <br />
                      <br />
                      1. {t('consentForm.declaration1')}
                      <br />
                      <br />
                      2. {t('consentForm.declaration2')}
                      <br />
                      <br />
                      3. {t('consentForm.declaration3') + (isHpvStudy ? t('consentForm.declaration3_1') : '')}
                      <br />
                      <br />
                      4. {t('consentForm.declaration4')}
                      <br />
                      <br />
                      {isHpvStudy ? '' : baseNumber + '. ' + t('consentForm.declaration5')}
                      {!isHpvStudy && <br />}
                      {!isHpvStudy && <br />}
                      {baseNumber + 1}.{' '}
                      {t('consentForm.declaration6') +
                        (isHpvStudy ? t('consentForm.declaration6_00') : '') +
                        t('consentForm.declaration6_0') +
                        (isHpvStudy ? (isEn ? '. ' : '。') : t('consentForm.declaration6_1'))}
                      <br />
                      <br />
                      {baseNumber + 2}. {t('consentForm.declaration7')}
                      <br />
                      <br />
                      {baseNumber + 3}.{' '}
                      {t('consentForm.declaration8') +
                        (isHpvStudy ? t('consentForm.declaration8_1') : '') +
                        t('consentForm.declaration8_2')}
                      <br />
                      <br />
                      {baseNumber + 4}. {t('consentForm.declaration9')}
                      <br />
                      <br />
                      {baseNumber + 5}. {t('consentForm.declaration10')}
                      <br />
                      <br />
                      <span className="" style={headingStyle}>
                        {t('consentForm.PersonalInformationCollectionStatement')}
                      </span>
                      <br />
                      <br />
                      {t('consentForm.PICS1')}
                      <br />
                      <br />
                      {t('consentForm.PICS2') + (isHpvStudy ? '' : t('consentForm.PICS2_1'))}
                      <br />
                      <br />
                      {t('consentForm.PICS3')}
                      <br />
                      <br />
                      {t('consentForm.PICS4') + (isHpvStudy ? '' : t('consentForm.PICS4_1')) + t('consentForm.PICS4_2')}
                      <br />
                      <br />
                      {t('consentForm.PICS5')}
                      <br />
                      <br />
                      <span className="" style={headingStyle}>
                        {t('consentForm.FurtherStorageAndUseOfData')}
                      </span>
                      <br />
                      <br />
                      {t('consentForm.FSAUOD1')}
                      <br />
                      <br />
                      {t('consentForm.FSAUOD2')}
                      <br />
                      <br />
                      {t('consentForm.FSAUOD3')}
                      <br />
                      <br />
                      {t('consentForm.FSAUOD4')}
                      <br />
                      <br />
                      <div className="flex">
                        <BaseCheckbox
                          onChange={onDisagreeTick4BoxChange}
                          style={{
                            marginRight: isLg ? '16px' : '0.3902rem',
                            marginTop: isLg ? '3px' : undefined,
                          }}
                          checked={disagreeTick4BoxValue}
                        />
                        <div>{t('consentForm.disagreeTick4')}</div>
                      </div>
                      <br></br>
                      {t('consentForm.FSAUOD5') +
                        (isHpvStudy ? t('consentForm.FSAUOD5_02') : t('consentForm.FSAUOD5_01')) +
                        (isHpvStudy ? '' : t('consentForm.FSAUOD5_1')) +
                        t('consentForm.FSAUOD5_2') +
                        (isHpvStudy ? '' : t('consentForm.FSAUOD5_3')) +
                        t('consentForm.FSAUOD5_4')}
                      <br />
                      <br />
                      {t('consentForm.FSAUOD6')}
                      <br />
                      <br />
                      <div className="flex">
                        <BaseCheckbox
                          onChange={onDisagreeTick5BoxChange}
                          style={{
                            marginRight: isLg ? '16px' : '0.3902rem',
                            marginTop: isLg ? '0px' : undefined,
                          }}
                          checked={disagreeTick5BoxValue}
                        />
                        <div>{t('consentForm.disagreeTick5')}</div>
                      </div>
                    </>
                  )}
                  <br></br>
                </div>
              </div>
              <div className="p-[18px_10px_22px_10px] lg:p-[24px_0px_28px_0px] lg:flex lg:justify-center lg:items-center">
                <CollectionBootleButton
                  htmlType="submit"
                  buttonClassName="w-[100%] h-[41px!important] md:w-[326px] md:h-[41px!important] md:text-[16px] md:mt-[0px] md:mb-[0px]"
                  value={t('agreenments.Agree')}
                  status={'default'}
                  onClick={() => {
                    onClickAgree();
                  }}
                  loading={isLoading}
                ></CollectionBootleButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Agreements;
