import { useTranslation } from 'react-i18next';
import CollectionHeader from '../components/CollectionHeader';
import { StepBox } from '../components/StepBox';
import { useParams } from 'react-router-dom';
import { CurPage } from '@consts/enums';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { COMMIT_TIME_POSITION, MEDIA_SIZE } from '@consts/consts';
import { useEffect, useState } from 'react';
import { CheckValidFunc, ThirdStepContext } from '../stepsContext';
import CustomSteps from '../components/CustomSteps';
import { useCheckValidAllSecondStep, useGetFormValue } from '@states/formValue.state';
import classNames from 'classnames';
import CollectionBootleButton from '../components/CollectionBootleButton';
import MessageText from '../components/MessageText';
import { useLocalNavigate } from 'src/router/routerTools';
import { useMemoizedFn } from 'ahooks';
import SubmitMethod from './components/SubmitMethod';
import PickUpDate from './components/PickUpDate';
import CollectionTime from './components/CollectionTime';
import OtherInfo from './components/OtherInfo';
import OtherInfo2 from './components/OtherInfo2';
import DropOffDate from './components/DropOffDate';
import { formatCollectionBootleDate, getAnswerArr, getDefaultCollectionBootleDate } from '@utils/index';
import { getAllValue } from '@utils/excel';
import ResultAlert from '@utils/globalComponents/ResultAlert';

import modalError from '@images/modalError.svg';
import modalTick from '@images/modalTick.svg';
import dayjs from 'dayjs';
import { post } from '@utils/request';
import { message } from 'antd';
import website from '@configs/website.json';
import Payment from '../components/Payment/Payment';
import useCheckSampleId from '../hooks/useCheckSampleId';

export default function ThirdStep() {
  const [isOnPayment, setIsOnPayment] = useState(false);
  const formValue = useGetFormValue();
  const [t] = useTranslation();
  const collectionBottleId = useParams().collectionBottleId;
  const [hasClickedNext, setHasClickedNext] = useState(false);
  hasClickedNext;
  const navigate = useLocalNavigate();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  isLg;
  const [checkValidFuncs, setCheckValidFuncs] = useState<CheckValidFunc[]>([]);
  const addCheckValidFunc = useMemoizedFn((func: CheckValidFunc) => {
    setCheckValidFuncs((funcs) => {
      return [...funcs, func];
    });

    return () => {
      setCheckValidFuncs((funcs) => {
        return funcs.filter((f) => f !== func);
      });
    };
  });
  const [hasError, setHasError] = useState(false);
  const checkAllValid = async () => {
    const results = await Promise.all(checkValidFuncs.map((func) => func()));
    return results.every((result) => result);
  };
  const [loading, setLoading] = useState(false);

  const alertErrorReminder = useMemoizedFn(() => {
    // console.log(modalStatus, 'modalStatus');
    ResultAlert((close) => ({
      content: (
        <div>
          <div className="flex justify-between items-center">
            <div className="w-[28px]  lg:w-[28px] mr-[10px] lg:mr-[10px]">
              <img src={modalError} alt="modalError" className="object-cover w-[28px]  cursor-pointer  lg:w-[28px]" />
            </div>
            <div className="font-[600] text-[16px] leading-[24px] text-[#25282B] lg:text-[16px] lg:leading-[24px]">
              {t('collectionBootle.SubmitFailedTitle')}
            </div>
            <div
              className="w-[14px]  lg:w-[14px] mr-[0px] ml-[auto] lg:mr-[0px]"
              onClick={() => {
                close();
                // await RtcAdapter.leave();
                // navigate('/meeting/exception?type=end');
              }}
            >
              <img src={modalTick} alt="modalTick" className="object-cover w-[14px]  cursor-pointer  lg:w-[14px]" />
            </div>
          </div>
          <div className="ml-[38px] lg:ml-[38px]  font-[400] text-[14px] leading-[22px] text-[#25282B] lg:text-[14px] lg:leading-[22px]">
            {t('collectionBootle.SubmitFailedText')}
          </div>
        </div>
      ),
    }));
  });

  const submitData = async () => {
    const allValue = getAllValue(t);
    const papSelectStatus = formValue[11];
    const HPVSelectStatus = formValue[15];
    const pickDropSelectStatus = formValue[19];
    const smearSelectStatus = formValue[13];
    const gender = formValue[5];

    const formatData = formatCollectionBootleDate(
      papSelectStatus,
      HPVSelectStatus,
      pickDropSelectStatus,
      smearSelectStatus,
      gender,
      allValue,
      formValue,
    );
    const answerArr = getAnswerArr(papSelectStatus, HPVSelectStatus, pickDropSelectStatus, smearSelectStatus, gender);

    if (answerArr.length === 0) {
      // TODO展示错误弹窗
      alertErrorReminder();
      return;
    }

    try {
      const submitTimeFormatDate = {
        ...formatData,
      };
      const defaultValue = getDefaultCollectionBootleDate(getAllValue(t));
      submitTimeFormatDate[COMMIT_TIME_POSITION as keyof typeof defaultValue].answer =
        dayjs().format('YYYY/MM/DD hh:mma') + '';

      const isPick = formValue[19] === 'PickUp';

      let cnPostion;

      cnPostion = localStorage.getItem('cnPosition') || '';

      const params = {
        hpvInfo: {
          sampleId: collectionBottleId,
          data: JSON.stringify(submitTimeFormatDate),
        },
        isDebug: false,
        userName: formValue[18],
        dateTime: isPick ? formValue[20] : formValue[-4],
        // 区分一下中英文
        position: isPick ? formValue[23] : formValue[22],
        cnPosition: isPick ? formValue[23] : cnPostion,
        specialCode: localStorage.getItem('specialCode') || undefined,
      };

      // 如果是pickup，则需要到支付页面
      if (isPick) {
        sessionStorage.setItem(website.payment_submit_data, JSON.stringify(params));
        sessionStorage.setItem(website.is_user_pick_up_submit, 'true');

        if (!(params.hpvInfo.sampleId as string)?.startsWith('0113')) {
          // 到达支付页面
          setIsOnPayment(true);
          return;
        }
      }

      await post('/hpv/add', params);
      localStorage.removeItem('formValue');
      localStorage.removeItem('collectionBootleId');
      localStorage.removeItem('cnPosition');
      localStorage.removeItem('specialCode');
      navigate('/collectionSuccess');
    } catch (err: any) {
      const responseMessage = err?.response?.data?.message;
      const errorMessage =
        (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || t('collectionBootle.error');
      message.error(errorMessage);
    }
  };

  const onCheckValid = async () => {
    setHasClickedNext(true);
    setLoading(true);
    try {
      const results = await checkAllValid();
      if (results) {
        submitData();
      }
      setHasError(!results);
    } catch (e) {
      console.error(e);
      setHasError(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // 检查第二步的信息是不是都填了
  useCheckValidAllSecondStep();
  const pickTime = formValue[21];
  const pickUpDate = formValue[20];
  const dropOffDate = formValue[-4];

  const [isShowCollectionTime, setIsShowCollectionTime] = useState(false);
  useEffect(() => {
    if ((pickTime && pickUpDate) || dropOffDate) {
      setIsShowCollectionTime(false);
      setTimeout(() => {
        setIsShowCollectionTime(true);
      }, 300);
    } else {
      setIsShowCollectionTime(false);
    }
  }, [dropOffDate, pickTime, pickUpDate]);
  useCheckSampleId();

  if (isOnPayment) {
    return <Payment setIsOnPayment={setIsOnPayment} />;
  }

  return (
    <ThirdStepContext.Provider
      value={{
        checkValidFuncs,
        addCheckValidFunc,
      }}
    >
      <div>
        <CollectionHeader />
        <StepBox step={3}>
          <div className="flex flex-col lg:mt-[40px] mt-[18px]"></div>
          <CustomSteps curPage={CurPage.Delivery} />
          <SubmitMethod hasClickedNext={hasClickedNext} />
          {formValue[19] === 'PickUp' && <PickUpDate hasClickedNext={hasClickedNext} />}
          {formValue[19] === 'DropOff' && <DropOffDate hasClickedNext={hasClickedNext} />}
          {isShowCollectionTime && <CollectionTime hasClickedNext={hasClickedNext} />}
          {isShowCollectionTime && <OtherInfo hasClickedNext={hasClickedNext} />}
          {isShowCollectionTime && <OtherInfo2 hasClickedNext={hasClickedNext} />}

          {/* 下面是两个按钮 */}
          <div
            className={classNames(
              formValue[5] === 'Male' ? 'mt-[0px] lg:mt-[0px]' : 'mt-[43px] lg:mt-[74px]',
              'flex flex-col w-[100%] ',
            )}
          >
            <div className="flex justify-between mt-[50px] lg:mt-[58px] relative">
              {hasError && (
                <MessageText
                  helpText={t('collectionBootle.clickNextErrorMessage') as string}
                  status={'error'}
                  style={{
                    position: 'absolute',
                    right: '0px',
                    top: isLg ? '-30px' : '-0.7317rem',
                  }}
                ></MessageText>
              )}
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-[170px] h-[50px] mb-[63px] md:w-[170px] md:h-[50px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px]"
                value={t('collectionBootle.Back')}
                status="check"
                onClick={() => {
                  navigate('/2ndStep/' + collectionBottleId);
                }}
              ></CollectionBootleButton>
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-[170px] h-[50px] mb-[63px]  md:w-[170px] md:h-[50px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px]"
                disable={false}
                value={t('collectionBootle.Submit')}
                status={'check'}
                onClick={onCheckValid}
                loading={loading}
              ></CollectionBootleButton>
            </div>
          </div>
        </StepBox>
      </div>
    </ThirdStepContext.Provider>
  );
}
