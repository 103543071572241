import { PickDropSelectType } from '@consts/enums';
import DeliveryAlert from '@pages/CollectionBootle/components/DeliveryAlert';
import { useMemoizedFn } from 'ahooks';

import modalTick from '@images/modalTick.svg';
import blueSigns from '@images/blueSigns.svg';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';
import { useTranslation } from 'react-i18next';

export default function useAlertDeliveryInfo(pickDropSelectStatus: string) {
  const defaultFontStyle = useGetFontStyle();
  const [t] = useTranslation();

  return useMemoizedFn((deliveryType: keyof typeof PickDropSelectType) => {
    const isPick = deliveryType
      ? deliveryType === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]]
      : pickDropSelectStatus === PickDropSelectType[PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]];

    DeliveryAlert((close) => ({
      content: (
        <div>
          <div className="flex justify-between items-start relative">
            <div className="w-[24px]  lg:w-[24px] mr-[12px] lg:mr-[12px]">
              <img src={blueSigns} alt="blueSigns" className="object-cover w-[24px]  cursor-pointer  lg:w-[24px]" />
            </div>
            <div className="flex flex-col">
              <div
                className="font-[600] text-[16px] leading-[24px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                style={{
                  fontFamily: defaultFontStyle,
                }}
              >
                {isPick ? t('collectionBootle.PickUpTitle') : t('collectionBootle.DropOffTitle')}
              </div>
              <div className="font-[600] text-[16px] leading-[24px] text-[#25282B] w-[65vw] lg:text-[16px] lg:leading-[24px] lg:w-[265px]">
                {isPick ? (
                  <div
                    className="font-[400] text-[14px] leading-[22px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                    style={{
                      fontFamily: defaultFontStyle,
                    }}
                  >
                    {t('collectionBootle.PickUpAlert1')}{' '}
                    <span
                      className="font-[600] text-[14px] leading-[22px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                      style={{
                        fontFamily: defaultFontStyle,
                      }}
                    >
                      {t('collectionBootle.PickUpAlert2')}
                    </span>{' '}
                    {t('collectionBootle.PickUpAlert3')}​{' '}
                  </div>
                ) : (
                  <div
                    className="font-[400] text-[14px] leading-[22px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                    style={{
                      fontFamily: defaultFontStyle,
                    }}
                  >
                    {t('collectionBootle.DropOffAlert1')}{' '}
                    <span
                      className="font-[600] text-[14px] leading-[22px] text-[rgba(0,0,0,0.85)] lg:text-[16px] lg:leading-[24px]"
                      style={{
                        fontFamily: defaultFontStyle,
                      }}
                    >
                      {t('collectionBootle.DropOffAlert2')}
                    </span>{' '}
                    {t('collectionBootle.DropOffAlert3')}
                  </div>
                )}
              </div>
            </div>
            {/* TODO 使用绝对定位 */}
            <div
              className="w-[14px]  lg:w-[14px] mr-[0px] ml-[auto] lg:mr-[0px] absolute top-[-9px] right-[-12px]  lg:top-[-9px] lg:right-[-12px]"
              onClick={() => {
                close();
              }}
            >
              <img src={modalTick} alt="modalTick" className="object-cover w-[14px]  cursor-pointer  lg:w-[14px]" />
            </div>
          </div>
        </div>
      ),
    }));
  });
}
