import { useEffect, useRef } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import scanQRCodeStyle from './scanQRCode.module.css';
import { useSearchParams } from 'react-router-dom';
const codeReader = new BrowserMultiFormatReader();

// or init with options
function scanQRCode(video?: HTMLVideoElement, collectionBottleId?: string | null) {
  codeReader.decodeFromVideoDevice(null, 'video', (result, error) => {
    if (result) {
      window.location.href = `/scanSuccess/${collectionBottleId}?res=${encodeURIComponent(result.getText())}`;
    }
    if (error) {
      // console.error(error);
      // video.play();
      // scanQRCode(video);
    }
  });
}

function startScan(video: HTMLVideoElement, collectionBottleId?: string | null) {
  // @ts-ignore
  window.navigator.getUserMedia =
    // @ts-ignore
    window.navigator.getUserMedia ||
    // @ts-ignore
    window.navigator.webkitGetUserMedia ||
    // @ts-ignore
    window.navigator.mozGetUserMedia ||
    // @ts-ignore
    window.navigator.msGetUserMedia;

  if (window.navigator.mediaDevices && window.navigator.mediaDevices.getUserMedia) {
    window.navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: {
            exact: 'environment',
          },
        },
      })
      .catch(() => {
        return window.navigator.mediaDevices.getUserMedia({
          video: true,
        });
      })
      .then(function (stream) {
        video.srcObject = stream;
        video.play();
        scanQRCode(video, collectionBottleId);
      });

    // @ts-ignore
  } else if (window.navigator.getUserMedia) {
    // @ts-ignore
    navigator.getUserMedia(
      {
        video: true,
      },
      function (stream: MediaProvider | null) {
        video.srcObject = stream;
        video.play();
        scanQRCode(video, collectionBottleId);
      },
      function (error: any) {
        console.error(error);
      },
    );
    return () => {
      video.pause();
      codeReader.reset();
    };
  }
}

export default function ScanQRCode() {
  const collectionBottleId = useSearchParams()[0].get('collectionBottleId');
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const t = setTimeout(() => {
      if (videoRef.current) {
        startScan(videoRef.current, collectionBottleId);
      }
    }, 300);
    return () => {
      clearTimeout(t);
    };
  }, [collectionBottleId]);

  return (
    <div className="w-full h-[100vh]">
      <video id="video" ref={videoRef} className="w-full h-[100vh] bg-slate-500 object-cover object-center" />
      <div className={scanQRCodeStyle['qr-scanner']}>
        <div className={scanQRCodeStyle.box}>
          <div className={scanQRCodeStyle.line}></div>
          <div className={scanQRCodeStyle.angle}></div>
        </div>
      </div>
    </div>
  );
}
