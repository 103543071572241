import { isEmail } from '@utils/index';
import { useMemoizedFn } from 'ahooks';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocalNavigate } from 'src/router/routerTools';

export const formValueState = atom<any>({
  key: 'formValueState',
  default: localStorage.getItem('formValue') ? JSON.parse(localStorage.getItem('formValue') as string) : {},
});

export const useSetFormValue = () => {
  const setFormValueInner = useSetRecoilState(formValueState);
  return useMemoizedFn((value: any) => {
    setFormValueInner((oldValue: any) => {
      const newValue = {
        ...oldValue,
        ...value,
      };
      localStorage.setItem('formValue', JSON.stringify(newValue));
      return newValue;
    });
  });
};

export const useGetFormValue = () => {
  return useRecoilValue(formValueState);
};

export const useCheckValidAllFirstStep = () => {
  const formValue = useGetFormValue();

  const navigate = useLocalNavigate();
  const collectionBottleId = useParams().collectionBottleId;

  useEffect(() => {
    const sampleId = formValue[0];

    if (!sampleId) {
      navigate('/agreements/' + collectionBottleId);
      return;
    }
    const phoneNumber = formValue[3];

    const isNotPhonePass = !phoneNumber || !isValidPhoneNumber(phoneNumber);
    const email = formValue[4];
    const isNotEmailPass = email ? email && !isEmail(email) : false;

    // const genderStr = formValue[5];
    // 性别暂时不需要验证
    const isNotGenderPass = false; // genderStr === undefined;
    if (isNotPhonePass || isNotEmailPass || isNotGenderPass) {
      navigate('/firstStep/' + collectionBottleId);
    }

    // 只在页面初始化时检查
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useCheckValidAllSecondStep = () => {
  const collectionBottleId = useParams().collectionBottleId;
  const formValue = useGetFormValue();

  const navigate = useLocalNavigate();

  useEffect(() => {
    const hasAggreed = () => {
      return !!formValue[18];
    };
    if (hasAggreed()) {
      return;
    }

    navigate('/2ndStep/' + collectionBottleId);
  }, [collectionBottleId, formValue, navigate]);
};
