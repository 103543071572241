import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import FiledInput, { FiledTitle } from './BaseComponent/FiledInput';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import BaseTextArea from '@pages/CollectionBootle/components/BaseTextArea';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import { BasePageComponentProps, PageComponentConfigMap } from './BaseComponent/PageComponentMap';

const s1 = 'Hong Kong Identity Card';
const s2 = "People's Republic of China Resident ID Card";
const s3 = 'Others';

type IDInputProps = BasePageComponentProps & PageComponentConfigMap['idNumber'];

export default function IDInput(props: IDInputProps) {
  if (props.titleConfigs.length !== 2) {
    throw new Error('IDInput titleConfigs length must be 2');
  }

  const idTypeTitle = props.titleConfigs[0].title;
  const idNumberTitle = props.titleConfigs[1].title;

  const { pageValues, setPageValue } = usePageContext();

  const initialIdt = pageValues[idTypeTitle]?.value;
  const initialIdtNumber = pageValues[idNumberTitle]?.value;

  const getRealInitialIdt = () => {
    if (initialIdt === undefined) {
      return undefined;
    } else if (initialIdt !== s1 && initialIdt !== s2) {
      return s3;
    } else {
      return initialIdt;
    }
  };

  const [t] = useTranslation();
  const [idt, setIdt] = useState<string | undefined>(getRealInitialIdt());
  const [idtTextArea, setIdtTextArea] = useState<string | undefined>(undefined);
  const [identityNo, setIdentityNo] = useState<string>(initialIdtNumber || '');

  const onIdentityNoChange = (v: string) => {
    setIdentityNo(v);
    setPageValue(idNumberTitle, v);
  };

  const [isIdtError, setIsIdtError] = useState(false);
  const [isIdentityNoError, setIsIdentityNoError] = useState(false);

  const s1Status = useMemo(() => {
    if (idt === s1) {
      return 'check';
    }

    if (isIdtError) {
      return 'emptyError';
    }
    return 'unchecked';
  }, [idt, isIdtError]);

  const s2Status = useMemo(() => {
    if (idt === s2) {
      return 'check';
    }

    if (isIdtError) {
      return 'emptyError';
    }
    return 'unchecked';
  }, [idt, isIdtError]);
  const s3Status = useMemo(() => {
    if (idt === s3) {
      return 'check';
    }

    if (isIdtError) {
      return 'emptyError';
    }
    return 'unchecked';
  }, [idt, isIdtError]);

  const idtPlaceholder = useMemo(() => {
    let newValue = '';
    if (idt === s1) {
      newValue = t('collectionBootle.question8Placeholder');
    } else if (idt === s2) {
      newValue = t('collectionBootle.question8Placeholder2');
    } else if (idt === s3) {
      newValue = t('collectionBootle.question8Placeholder3');
    } else {
      newValue = t('collectionBootle.question8Placeholder');
    }
    return newValue;
  }, [idt, t]);

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  useAddCheckValidFunc(() => {
    if (!idt) {
      setIsIdtError(true);
      return false;
    }

    if (idt === s3 && !idtTextArea) {
      setIsIdtError(true);
      return false;
    }
    setIsIdtError(false);

    if (!identityNo) {
      setIsIdentityNoError(true);
      return false;
    }
    setIsIdentityNoError(false);

    return true;
  });

  const handleClickButton = (value: string) => {
    setIsIdtError(false);
    if (idt === value) {
      return;
    }
    setPageValue(idTypeTitle, value);

    setIdt(value);
    setIdentityNo('');
    setIdtTextArea('');
  };

  return (
    <>
      <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[45px]">
        <FiledTitle title={t('collectionBootle.question7')} />
        <CollectionBootleButton
          htmlType="submit"
          buttonClassName="w-full h-[38px] mb-[18px]  lg:w-[30vw] lg:h-[38px] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
          value={t('collectionBootle.question7HK')}
          status={s1Status}
          onClick={() => handleClickButton(s1)}
        ></CollectionBootleButton>
        <CollectionBootleButton
          htmlType="submit"
          buttonClassName="w-full h-[38px] mb-[18px]  lg:w-[30vw] lg:h-[38px] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
          value={t('collectionBootle.question7ID')}
          status={s2Status}
          onClick={() => handleClickButton(s2)}
        ></CollectionBootleButton>
        {/* TODO: other这里也需要填写 */}
        <CollectionBootleButton
          htmlType="submit"
          buttonClassName="w-full h-[38px] mb-[0px]  lg:w-[30vw] lg:h-[38px] lg:text-[14px] lg:mt-[0px] lg:mb-[0px]"
          value={t('collectionBootle.question7Others')}
          status={s3Status}
          // status={idt === 'Passport' ? 'check' : 'unchecked'}
          onClick={() => handleClickButton(s3)}
        ></CollectionBootleButton>
        {idt === s3 && (
          <div className="font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px]  mb-[18px]  lg:text-[14px] lg:leading-[20px] lg:mt-[0px]  lg:mb-[26px]">
            {t('collectionBootle.PleaseSpecify')}
          </div>
        )}
        {idt === s3 && (
          <BaseTextArea
            placeholder={t('collectionBootle.EnterTypeMessage') as string}
            disable={!(idt === s3)}
            defaultValue={idtTextArea}
            onChange={(e) => {
              setIdtTextArea(e.target.value);
              setPageValue(idTypeTitle, e.target.value);
            }}
            collectionDatePickerClassName={isIdtError ? 'my-select-selector-empty-type' : undefined}
          ></BaseTextArea>
        )}
        {isIdtError && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              marginTop: isLg ? '11px' : '0.2683rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </div>
      {/* Identity No. */}

      <FiledInput
        fontFamilyValue={'Montserrat'}
        value={identityNo}
        defaultValue={initialIdtNumber}
        onChange={onIdentityNoChange}
        title={`${t('collectionBootle.question8')}${t('collectionBootle.question8Remind')}`}
        placeholder={idtPlaceholder as string}
        filedInputClassName="mt-[40px] mb-[46px]  lg:mb-[59px]"
        status={isIdentityNoError ? 'error' : undefined}
        showPop={true}
        formName={'IdentityNo'}
        overlayStyle={{
          width: (idt === s1 || idt === s2 || !idt) && !isLg ? '80%' : undefined,
        }}
        // isSubmitCheck={isSubmitCheck}
      />
    </>
  );
}
