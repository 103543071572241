import { DISAGREE_FIRST_POSITION, MEDIA_SIZE } from '@consts/consts';
import BaseTextArea from '@pages/CollectionBootle/components/BaseTextArea';
import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import enLocales from '@locales/en.json';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';

const whereList = [
  { label: 'collectionBootle.question23Select1', value: enLocales.collectionBootle.question23Select1 },
  { label: 'collectionBootle.question23Select2', value: enLocales.collectionBootle.question23Select2 },
  { label: 'collectionBootle.question23Select3', value: enLocales.collectionBootle.question23Select3 },
  { label: 'collectionBootle.question23Select4', value: enLocales.collectionBootle.question23Select4 },
  { label: 'collectionBootle.question23Select5', value: enLocales.collectionBootle.question23Select5 },
  { label: 'collectionBootle.question23Select6', value: enLocales.collectionBootle.question23Select6 },
  { label: 'collectionBootle.question23Select7', value: enLocales.collectionBootle.question23Select7 },
  { label: 'collectionBootle.question23Select8', value: enLocales.collectionBootle.question23Select8 },
];

const getWhereInit = (v: string) => {
  if (whereList.find((item) => item.value === v)) {
    return v;
  }

  if (!v) {
    return undefined;
  }
  return enLocales.collectionBootle.question23Select8;
};

const getInitWhereTextArea = (v: string) => {
  const a = getWhereInit(v);

  if (a === enLocales.collectionBootle.question23Select8) {
    return v;
  }
  return '';
};

export default function OtherInfo(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [whereLearn, setWhereLearn] = useState<string | undefined>(
    getWhereInit(formValue[DISAGREE_FIRST_POSITION - 5]),
  );

  const [whereLearnTextArea, setWhereLearnTextArea] = useState<string>(
    getInitWhereTextArea(formValue[DISAGREE_FIRST_POSITION - 5]),
  );

  useAddThirdStepCheckValidFunc(() => {
    if (whereLearn === enLocales.collectionBootle.question23Select8) {
      return whereLearnTextArea !== '';
    }
    return !!whereLearn;
  });
  const isWhereError = !whereLearn && hasClickedNext;
  return (
    <>
      <p
        className="font-[600] text-[16px] text-[#25282B] leading-[20px] tracking-[0.4px] mt-[40px] lg:mt-[45px] lg:mb-[8px] mb-[8px] lg:text-[20px] flex lg:leading-[24px] lg:tracking-[0.4px] "
        // style={{
        //   marginTop: isLg ? '0px' : '0rem',
        //   marginBottom: isLg ? '0px' : '0rem',
        // }}
      >
        {t('collectionBootle.otherInformation')}
      </p>
      {/* Where did you learn about the INDICAID HPV Urine Test? */}
      <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[45px]">
        <FiledTitle title={`${t('collectionBootle.question23')}`} />
        {whereList.map((item) => {
          return (
            <CollectionBootleButton
              key={item.value}
              style={{
                height: isLg ? '90px' : '1.9024rem',
                whiteSpace: 'pre-wrap',
              }}
              htmlType="submit"
              buttonClassName="w-full  mb-[18px]  lg:w-[26.66vw] lg:text-[14px] lg:mt-[0px] lg:mb-[18px]"
              value={t(item.label)}
              status={isWhereError ? 'emptyError' : whereLearn === item.value ? 'check' : 'unchecked'}
              onClick={() => {
                setWhereLearn(item.value);
                if (item.value === enLocales.collectionBootle.question23Select8) {
                  return;
                }
                // 清空之前的表单
                setFormValue({
                  [DISAGREE_FIRST_POSITION - 5]: item.value,
                });
              }}
            ></CollectionBootleButton>
          );
        })}
        {whereLearn === enLocales.collectionBootle.question23Select8 && (
          <div className="font-[400] text-[#25282B] text-[14px] leading-[28px] tracking-[0.4px]  mb-[18px]  lg:text-[14px] lg:leading-[20px] lg:mt-[0px]  lg:mb-[26px]">
            {t('collectionBootle.PleaseSpecify')}
          </div>
        )}
        {whereLearn === enLocales.collectionBootle.question23Select8 && (
          <BaseTextArea
            placeholder={t('collectionBootle.EnterTypeMessage') as string}
            disable={!(whereLearn === enLocales.collectionBootle.question23Select8)}
            defaultValue={whereLearnTextArea}
            onChange={(e) => {
              const v = e.target.value;
              if (v) {
                setFormValue({
                  [DISAGREE_FIRST_POSITION - 5]: v,
                });
              } else {
                setFormValue({
                  [DISAGREE_FIRST_POSITION - 5]: undefined,
                });
              }
              setWhereLearnTextArea(e.target.value);
            }}
            collectionDatePickerClassName={classNames(isWhereError && 'my-select-selector-empty-type')}
          ></BaseTextArea>
        )}
        {isWhereError && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              marginTop: isLg ? '11px' : '0.2683rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </div>
      {/* Where did you learn about the INDICAID HPV Urine Test? */}
    </>
  );
}
