import { useMemoizedFn } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FiledInput from './BaseComponent/FiledInput';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import { BasePageComponentProps, PageComponentConfigMap } from './BaseComponent/PageComponentMap';
import MessageText from '@pages/CollectionBootle/components/MessageText';

type LastNameInputProps = BasePageComponentProps & PageComponentConfigMap['lastName'];

export default function LastNameInput(props: LastNameInputProps) {
  const { pageValues, setPageValue } = usePageContext();
  const [isError, setIsError] = useState(false);

  const onValueChange = (v: string) => {
    setPageValue(props.title, v);
  };

  const value = pageValues[props.title]?.value;
  const [t] = useTranslation();
  const [lastName, setLastName] = useState(value || '');

  const handleLastNameChange = (v: string) => {
    setLastName(v);
    onValueChange(v);
  };

  const checkValid = useMemoizedFn(() => {
    if (lastName) {
      return true;
    }
    setIsError(true);
    return false;
  });

  useAddCheckValidFunc(checkValid);

  return (
    <>
      <FiledInput
        value={lastName}
        onChange={handleLastNameChange}
        title={t('collectionBootle.question1')}
        placeholder={t('collectionBootle.question1Placeholder')}
        filedInputClassName="mt-[29px] lg:mt-[29px]"
        forceUppercase={true}
        status={isError ? 'error' : undefined}
      />
      {isError && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: '5px',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </>
  );
}
