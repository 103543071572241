export default function ChinesePart(props: { children: JSX.Element }) {
  return (
    <div>
      閣下獲邀參與本項臨床研究。在閣下決定參與本項臨床研究之前，請細閱本知情同意書，確保閣下知曉本項臨床研究之目的、程序、風險及益處，以便閣下能夠作出知情的決定。若有任何疑慮或問題，請諮詢相達生物科技。若閣下決定參與本項臨床研究，請在本同意書上簽字並提交。
      <br />
      <p className="underline">個人資訊收集聲明</p>
      本知情同意書中所載的個人資料由閣下出於自願向相達生物科技提供。但是，若不提供個人資料，閣下可能無法參與本項臨床研究。閣下的個人資料僅用於確定閣下是在知情的情況下同意參與本項臨床研究。閣下的個人資料將僅與相達生物科技共享，除非閣下明確同意，否則不會與任何其他第三方共享。根據香港《個人資料（私隱）條例》，閣下有權查閱閣下的個人資料，若有不準確之處，可要求作出更正。若要提交資料查閲或更正要求，請致電3700
      8888或電郵至cs@indicaid.com 聯絡相達生物科技客戶服務主任。
      除本知情同意書中所示的個人資料外，相達生物科技不會在本項臨床研究中收集閣下的任何其他個人資料。
      <br />
      <p className="underline">目的</p>
      本項臨床研究的目的是驗證一款HPV尿液測試，並不旨在透過參與本項臨床研究為閣下提供任何直接醫療益處。
      <br />
      <p className="underline">參與者甄選</p>
      我們邀請十八歲或以上人士參與本項臨床研究。（甄選標準）
      <br />
      <p className="underline">申辦方</p>
      Phase Scientific International Limited（相達生物科技）
      <br />
      <p className="underline">采樣持續時間</p>
      每次採集約10分鐘
      <br />
      <p className="underline">采樣地點</p>
      參與者家中
      <br />
      <p className="underline">采樣程序</p>
      1.
      閣下將採集一份包括前段尿的尿液樣本，並將樣本送至相達生物科技的實驗室進行HPV檢測。請從尿液收集套裝上查看詳細的樣本採集程序。
      <br />
      <p className="underline">風險</p>
      1.
      尿液是一種體液，並且被認為是一種生物危害品，建議使用者戴手套或在採集前後洗手。請使用生物危害品袋來存放裝有尿液的收集瓶。
      2. 由於衛生原因，使用者應避免尿液收集瓶與生殖器直接接觸。
      <br />
      <p className="underline">保密</p>
      作為臨床分析之一部分，閣下的樣本和/或檢測結果將與本項臨床研究其他參與者的樣本和檢測結果混在一起，只會透過一個號碼（而非閣下的姓名）來識別閣下。
      <br />
      <p className="underline">鼓勵</p>
      一份HPV檢測報告
      <br />
      <p className="underline">終止</p>
      於下列情況下，相達生物科技可隨時終止閣下參與本項臨床研究：
      <br />
      • 閣下無法滿足甄選標準；或
      <br />• 出於任何原因，研究者認為終止參與本項臨床研究符合閣下的最佳利益。
      <p className="underline">問題</p>
      若閣下對本項臨床研究有任何疑問，請以電郵方式聯絡相達生物科技，電郵地址為：cs@indicaid.com。
      <br />
      <p className="underline">促銷推廣</p>
      相達生物科技打算使用閣下的聯絡資料（包括閣下的電郵地址、聯絡電話號碼和地址（如有提供
      ），用於向閣下發送有關相達生物科技的產品及／或服務的宣傳和直接促銷資訊及邀請閣下參與研究，為此我們需要徵得閣下的同意。可促銷的產品及服務類別為與身心健康相關之產品及服務。我們亦希望向我們的關聯機構提供上述資料，以便其能夠向閣下直接促銷與身心健康相關之其他產品及服務，為此我們需要徵得閣下的書面同意。
      <br />
      若閣下在作出同意後，不再希望我們使用或向他人提供閣下的個人資料作上文所述之直接促銷推廣用途，閣下可致電3700
      8888或發送電郵至cs@indicaid.com，或透過相達生物科技不時公佈的其他渠道，聯絡相達生物科技的客戶服務團隊，撤回閣下的同意，且閣下無需承擔任何費用。
      <br />
      <br />
      <div className="flex">
        {props.children}
        <div> 本人不同意以於上方“促銷推廣”段所述的方式使用及提供本人的個人資料作直接促銷推廣用途。</div>
      </div>
      <br />
      <p className="underline">參與者聲明</p>
      本人特此在本知情同意書上簽字，以確認：
      <br />
      <br />
      1.本人已閱讀並理解本知情同意書，並且其中內容已向本人説明。本人曾有機會就本項臨床研究提問，並且本人的問題（如有）已得到令本人滿意的回答。本人知曉，若在簽署本知情同意書之前對參與本項臨床研究有任何其他疑問，本人可聯絡相達生物科技。
      <br />
      2.本人知曉，本人是出於自願參與本項臨床研究。本人知曉，本人的尿液樣本一旦被提供給相達生物科技，本人將無法從研究中取回本人的樣本。
      <br />
      3.本人知曉，若本人因參與本項臨床研究而受傷，需要接受醫學治療，本項臨床研究並無為此撥備任何資金。相關醫療費用將由本人自行承擔。
      <br />
      4.本人知曉，本人將不會獲得任何補償或在任何方面獲益。
      <br />
      5.相達生物科技可存儲並在以後的研發活動中使用本人的樣本和/或檢測結果，且本人對可能源自本人所提供樣本和/或檢測結果的產品不享有任何權利或權益。本項臨床研究並無撥備資金用作額外補償。
      <br />
      6.雖在本知情同意書上簽字並注明日期，本人並未放棄本人的任何法定權利。
    </div>
  );
}
