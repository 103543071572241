import { MEDIA_SIZE } from '@consts/consts';
import { PickDropSelectType } from '@consts/enums';
import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import useAlertDeliveryInfo from './alertDeliveryInfo';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';

export default function SubmitMethod(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [pickDropSelectStatus, setPickDropSelectStatus] = useState<string>(formValue[19] || undefined);

  const isError = formValue[19] === undefined && hasClickedNext;

  const alertDeliveryInfo = useAlertDeliveryInfo(pickDropSelectStatus);
  useAddThirdStepCheckValidFunc(() => {
    if (pickDropSelectStatus === undefined) {
      return false;
    }
    return true;
  });

  return (
    <div className="flex flex-col w-[100%]  mt-[43px] lg:mt-[74px]">
      <FiledTitle title={t('collectionBootle.question16')} />
      <CollectionBootleButton
        htmlType="submit"
        buttonClassName="w-full h-[38px]  mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px]  md:mt-[0px] md:mb-[18px]"
        value={t('collectionBootle.Drop_Off')}
        status={isError ? 'emptyError' : pickDropSelectStatus === 'DropOff' ? 'check' : 'unchecked'}
        onClick={() => {
          const val = 'DropOff';
          setPickDropSelectStatus(val as keyof typeof PickDropSelectType);
          setFormValue({
            19: val,
          });
          // 展示弹窗
          alertDeliveryInfo(
            PickDropSelectType[
              PickDropSelectType['DropOff' as keyof typeof PickDropSelectType]
            ] as keyof typeof PickDropSelectType,
          );
        }}
      ></CollectionBootleButton>
      <CollectionBootleButton
        htmlType="submit"
        buttonClassName="w-full h-[38px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[45px]"
        value={t('collectionBootle.PickUpButtonText')}
        status={isError ? 'emptyError' : pickDropSelectStatus === 'PickUp' ? 'check' : 'unchecked'}
        onClick={() => {
          const val = 'PickUp';
          setPickDropSelectStatus(val as keyof typeof PickDropSelectType);
          setFormValue({
            19: val,
          });
          // 展示弹窗
          alertDeliveryInfo(
            PickDropSelectType[
              PickDropSelectType['PickUp' as keyof typeof PickDropSelectType]
            ] as keyof typeof PickDropSelectType,
          );
        }}
      ></CollectionBootleButton>
      {isError && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={isError ? 'error' : undefined}
          style={{
            marginTop: isLg ? '0px' : '0rem',
            position: 'relative',
            top: isLg ? '-30px' : '0.0976rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </div>
  );
}
