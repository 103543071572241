import { CompaginConfig } from '../BaseComponent/ConfigPage.type';

const pageConfigs = [
  {
    name: 'Personal Information',
    nameCn: '個人資料',
    components: [
      {
        type: 'lastName',
        title: 'Last Name',
        titleCn: '姓',
      },
      {
        type: 'firstName',
        title: 'First Name',
        titleCn: '名',
        value: '',
      },
      {
        type: 'phone',
        title: 'Phone',
        titleCn: '電話',
      },
      {
        type: 'email',
        title: 'Email',
        titleCn: '電郵',
        required: false,
      },
      {
        type: 'buttonSelect',
        title: 'Gender',
        titleCn: '性別',
        configs: [
          {
            value: 'Female',
            label: 'Female',
            labelCn: '女',
          },
          {
            value: 'Male',
            label: 'Male',
            labelCn: '男',
          },
        ],
      },
      {
        type: 'birthday',
        title: 'Birthday',
        titleCn: '生日',
      },
      {
        type: 'idNumber',
        title: 'ID Number',
        titleCn: '身份證號碼',
        titleConfigs: [
          {
            title: 'Identification Document Type',
            titleCn: '身份證件類型',
          },
          {
            title: 'Identification Document No.',
            titleCn: '證件號碼',
          },
        ],
      },
    ],
  },
  {
    name: 'HPV vaccination and screening history',
    nameCn: 'HPV疫苗接種和篩查史',
    components: [
      {
        type: 'buttonSelect',
        title: 'Have you had HPV vaccination?',
        titleCn: '您是否接種過HPV疫苗？',
        configs: [
          {
            value: 'Yes',
            label: 'Yes',
            labelCn: '有',
            components: [
              {
                type: 'buttonSelect',
                title: 'Type of HPV vaccine',
                titleCn: 'HPV疫苗的種類',
                configs: [
                  {
                    value: '2vHPV (Cervarix)',
                    label: '2vHPV (Cervarix)',
                    labelCn: '二價疫苗 (卉妍康) ',
                  },
                  {
                    value: '4vHPV ( Gardasil )',
                    label: '4vHPV ( Gardasil )',
                    labelCn: '四價疫苗 (4合1加衛苗)',
                  },
                  {
                    value: '9vHPV ( Gardasil )',
                    label: '9vHPV ( Gardasil )',
                    labelCn: '九價疫苗 (9合1加衛苗)',
                  },
                  {
                    value: 'Unknown',
                    label: 'Unknown',
                    labelCn: '不清楚',
                  },
                ],
              },
            ],
          },
          {
            value: 'No',
            label: 'No',
            labelCn: '没有',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title:
          "When was the most recent time you had a Pap smear, excluding the one conducted in last year's clinical study?",
        titleCn: '除了去年臨床研究中進行的檢測外，你最近一次做抹片檢查是什麼時候？',

        configs: [
          {
            value: 'Never',
            label: 'Never',
            labelCn: '從來沒有',
          },
          {
            value: 'Less than 1 year ago',
            label: 'Less than 1 year ago',
            labelCn: '少於一年',
          },
          {
            value: 'Less than 3 years ago',
            label: 'Less than 3 years ago',
            labelCn: '少於三年',
          },
          {
            value: 'More than 3 years ago',
            label: 'More than 3 years ago',
            labelCn: '多於三年',
          },
          {
            value: 'More than 5 years ago',
            label: 'More than 5 years ago',
            labelCn: '多於五年',
          },
          {
            value: 'Unknown',
            label: 'Unknown',
            labelCn: '不清楚',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'What was your HPV test result in the pilot study last year?',
        titleCn: '在去年的臨床研究中，你的HPV檢測結果是什麼？',
        configs: [
          {
            value: 'Positive',
            label: 'Positive',
            labelCn: '陽性',
          },
          {
            value: 'Negative',
            label: 'Negative',
            labelCn: '陰性',
          },
          {
            value: 'Unknown',
            label: 'Unknown',
            labelCn: '不清楚',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'In the pilot study last year, did you undergo the recommended Pap smear test as a follow-up test?',
        titleCn: '在去年的臨床研究中，您是否接受了推薦的抹片檢查作為後續檢查？',
        configs: [
          {
            value: 'Yes',
            label: 'Yes',
            labelCn: '有',
            components: [
              {
                type: 'buttonSelect',
                title:
                  'Why were you willing to undergo smear testing as a follow-up check in last year’s pilot study? (Can choose more than one)',
                titleCn: '您為什麼願意接受抹片檢查作為後續檢查？ (可以選擇多個答案)',
                isLarge: true,
                maxSelect: 6,
                minSelect: 1,
                configs: [
                  {
                    value: 'Concerned about my health',
                    label: 'Concerned about my health',
                    labelCn: '擔心自己的健康',
                  },
                  {
                    value: 'Followed the advice of healthcare providers',
                    label: 'Followed the advice of healthcare providers',
                    labelCn: '聽從醫療保健提供者的建議',
                  },
                  {
                    value: 'Concerns about potential health risks identified in initial Urine HPV Test',
                    label: 'Concerns about potential health risks identified in initial Urine HPV Test',
                    labelCn: '對尿液HPV檢測中發現的潜在健康風險的擔憂',
                  },
                  {
                    value: 'Free test offer',
                    label: 'Free test offer',
                    labelCn: '免費測試',
                  },
                  {
                    value: 'Curiosity about the process and results',
                    label: 'Curiosity about the process and results',
                    labelCn: '對過程和結果的好奇心',
                  },
                  {
                    value: 'Others',
                    label: 'Others',
                    labelCn: '其他',
                    needTextInput: true,
                  },
                ],
              },
            ],
          },
          {
            value: 'No',
            label: 'No',
            labelCn: '没有',
            components: [
              {
                type: 'buttonSelect',
                title:
                  'Why were you not willing to undergo smear testing as a follow-up check in last year’s pilot study? (Can choose more than one)',
                titleCn: '您為什麼不願意接受抹片檢查作為後續檢查？ (可以選擇多個答案)',
                isLarge: true,
                maxSelect: 6,
                minSelect: 1,
                configs: [
                  {
                    value: 'Uncomfortable with the testing procedure',
                    label: 'Uncomfortable with the testing procedure',
                    labelCn: '對測試程序感到不舒服',
                  },
                  {
                    value: 'Lack of time or availability',
                    label: 'Lack of time or availability',
                    labelCn: '沒有時間或不便參加',
                  },
                  {
                    value: 'Concerns about the accuracy of the test',
                    label: 'Concerns about the accuracy of the test',
                    labelCn: '對測試準確性表示擔憂',
                  },
                  {
                    value: 'Lack of understanding about the importance of the test',
                    label: 'Lack of understanding about the importance of the test',
                    labelCn: '對測試的重要性缺乏瞭解',
                  },
                  {
                    value: 'Fear of potential health risks associated with the test',
                    label: 'Fear of potential health risks associated with the test',
                    labelCn: '擔心與測試相關的潜在健康風險',
                  },
                  {
                    value: 'Others',
                    label: 'Others',
                    labelCn: '其他',
                    needTextInput: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'Which method do you prefer the MOST for cervical screening?',
        titleCn: '哪一種方法會作為您子宮頸篩查的首選？',
        isLarge: true,
        configs: [
          {
            value: 'Self-collecting Urine sample for HPV testing',
            label: 'Self-collecting Urine sample for HPV testing',
            labelCn: '自我收集尿液樣本做HPV檢測',
          },
          {
            value: 'Self-collecting vaginal swab for HPV testing',
            label: 'HSelf-collecting vaginal swab for HPV testing',
            labelCn: '自我收集陰道拭子做HPV檢測',
          },
          {
            value:
              'Physician conducting a speculum (vaginal) examination to collect a sample from the cervix for Pap smear or HPV testing',
            label:
              'Physician conducting a speculum (vaginal) examination to collect a sample from the cervix for Pap smear or HPV testing',
            labelCn: '醫生進行窺器（陰道）檢查並收集子宮頸樣本做抹片檢查或HPV檢測',
          },
          {
            value: 'No preference',
            label: 'No preference',
            labelCn: '沒有偏好',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'Sample(s) you are willing to provide (Can choose more than one):',
        titleCn: '您願意提供的樣本 (可以選擇多個答案):',
        minSelect: 1,
        maxSelect: 3,
        configs: [
          {
            value: 'Urine',
            label: 'Urine',
            labelCn: '尿液',
          },
          {
            value: 'Self-collected vaginal swab',
            label: 'Self-collected vaginal swab',
            labelCn: '自我採樣陰道拭子',
          },
          {
            value: 'Cervical smear',
            label: 'Cervical smear',
            labelCn: '宮頸塗片',
          },
        ],
      },
      {
        type: 'feedbackSelect',
        title: 'Your views and feedback on different collection methods:',
        titleCn: '您對不同採集方法的看法和用後感：',
        feedbackConfigs: [
          {
            title: 'Overall, how did you feel about the sample collection process?',
            titleCn: '總括來說，您對樣本採集過程有何看法?',
            feedbackMap: [
              {
                label: 'Very bad',
                labelCn: '很差',
                value: '1',
              },
              {
                label: 'Bad',
                labelCn: '差',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '一般',
                value: '3',
              },
              {
                label: 'Good',
                labelCn: '好',
                value: '4',
              },
              {
                label: 'Very good',
                labelCn: '很好',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },
          {
            title: 'How simple was the process of collecting this sample?',
            titleCn: '您認為收集樣本的過程簡單嗎?',
            feedbackMap: [
              {
                label: 'Very hard',
                labelCn: '很困難',
                value: '1',
              },
              {
                label: 'Hard',
                labelCn: '困難',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '中等',
                value: '3',
              },
              {
                label: 'Easy',
                labelCn: '容易',
                value: '4',
              },
              {
                label: 'Very easy',
                labelCn: '很容易',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },

          {
            title: 'How convenient was it to collect this sample?',
            titleCn: '您認為收集這個樣本方便嗎?',
            feedbackMap: [
              {
                label: 'Very inconvenient',
                labelCn: '很不方便',
                value: '1',
              },
              {
                label: 'Inconvenient',
                labelCn: '不方便',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '一般',
                value: '3',
              },
              {
                label: 'Convenient',
                labelCn: '方便',
                value: '4',
              },
              {
                label: 'Very convenient',
                labelCn: '很方便',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },

          {
            title: 'Did you feel embarrassed while collecting this sample?',
            titleCn: '您在收集樣本時感到尷尬嗎?',
            feedbackMap: [
              {
                label: 'Very embarrassed',
                labelCn: '很尷尬',
                value: '1',
              },
              {
                label: 'Embarrassed',
                labelCn: '尷尬',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '一般',
                value: '3',
              },
              {
                label: 'Not embarrassed',
                labelCn: '不尷尬',
                value: '4',
              },
              {
                label: 'Not embarrassed at all',
                labelCn: '完全不尷尬',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },
          {
            title: 'Did you experience any discomfort /pain while collecting this sample?',
            titleCn: '您在收集樣本時感到不適/疼痛嗎?',
            feedbackMap: [
              {
                label: 'Severe discomfort / pain',
                labelCn: '嚴重的不適/疼痛',
                value: '1',
              },
              {
                label: 'Quite uncomfortable / painful',
                labelCn: '相當不適/疼痛',
                value: '2',
              },
              {
                label: 'Some discomfort / pain; Can tolerate',
                labelCn: '一些不適/疼痛，但能够忍受',
                value: '3',
              },
              {
                label: 'Mild discomfort / pain',
                labelCn: '輕度不適/疼痛',
                value: '4',
              },
              {
                label: 'No discomfort / pain',
                labelCn: '沒有不適/疼痛',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },

          {
            title: 'How confident are you in the accuracy of the sample collection process?',
            titleCn: '您對樣本採集過程的準確性有多大信心？',
            feedbackMap: [
              {
                label: 'Not confident at all',
                labelCn: '完全沒有信心',
                value: '1',
              },
              {
                label: 'Not confident',
                labelCn: '沒有信心',
                value: '2',
              },
              {
                label: 'Neutral',
                labelCn: '一般',
                value: '3',
              },
              {
                label: 'Confident',
                labelCn: '有信心',
                value: '4',
              },
              {
                label: 'Very confident',
                labelCn: '很有信心',
                value: '5',
              },
            ],
            items: [
              {
                label: 'Urine',
                labelCn: '尿液樣本',
              },
              {
                label: 'Vaginal Swab',
                labelCn: '陰道拭子樣本',
              },
            ],
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'Would you be willing to do the HPV test again in the future? (Urine)',
        titleCn: '您是否願意以後再次進行HPV測試? (尿液樣本)',
        configs: [
          {
            value: 'Yes',
            label: 'Yes',
            labelCn: '是',
            components: [
              {
                type: 'buttonSelect',
                title: 'Why are you willing to do the HPV test (Urine) again? (can choose more than one)',
                titleCn: '您為什麼願意再次進行HPV測試(尿液樣本)？(可以選擇多個答案)',
                isLarge: true,
                minSelect: 1,
                maxSelect: 6,
                configs: [
                  {
                    value: 'Sampling process is straightforward',
                    label: 'Sampling process is straightforward',
                    labelCn: '採樣過程簡便',
                  },
                  {
                    value: 'Sampling is convenient',
                    label: 'Sampling is convenient',
                    labelCn: '採樣方便',
                  },
                  {
                    value: 'I am confident in the accuracy of the sampling',
                    label: 'I am confident in the accuracy of the sampling',
                    labelCn: '我對採樣過程的準確性有信心',
                  },
                  {
                    value: 'I did not feel awkward during the sampling process',
                    label: 'I did not feel awkward during the sampling process',
                    labelCn: '我在採樣過程中沒有感到尷尬',
                  },
                  {
                    value: 'I did not feel uncomfortable / painful during the sampling process',
                    label: 'I did not feel uncomfortable / painful during the sampling process',
                    labelCn: '我在採樣過程中並沒有感到不適或疼痛',
                  },
                  {
                    value: 'I can save time by not having to visit the clinic',
                    label: 'I can save time by not having to visit the clinic',
                    labelCn: '我可以省去前往診所的時間',
                  },
                ],
              },
            ],
          },
          {
            value: 'No',
            label: 'No',
            labelCn: '否',
            components: [
              {
                type: 'buttonSelect',
                title: 'Why are you not willing to do the HPV test (Urine) again? (can choose more than one)',
                titleCn: '您為什麼不願意再次進行HPV測試(尿液樣本)？(可以選擇多個答案)',
                isLarge: true,
                minSelect: 1,
                maxSelect: 6,
                configs: [
                  {
                    value: 'Sampling process is difficult',
                    label: 'Sampling process is difficult',
                    labelCn: '採樣過程較困難',
                  },
                  {
                    value: 'Sampling process is cumbersome',
                    label: 'Sampling process is cumbersome',
                    labelCn: '採樣過程繁瑣',
                  },
                  {
                    value: 'I am not confident in the accuracy of the sampling',
                    label: 'I am not confident in the accuracy of the sampling',
                    labelCn: '我對採樣過程的準確性沒有信心',
                  },
                  {
                    value: 'I feel awkward during the sampling process',
                    label: 'I feel awkward during the sampling process',
                    labelCn: '我對採樣過程感到尷尬',
                  },
                  {
                    value: 'I feel uncomfortable/painful during the sampling process',
                    label: 'I feel uncomfortable/painful during the sampling process',
                    labelCn: '我在採樣過程中感到不適或疼痛',
                  },
                  {
                    value: "I'm worried that I might get hurt during the sampling process",
                    label: "I'm worried that I might get hurt during the sampling process",
                    labelCn: '我擔心採樣過程中會受傷',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'Would you be willing to do the HPV test again in the future? (Vaginal Swab)',
        titleCn: '您是否願意以後再次進行HPV測試? (陰道拭子樣本)',
        configs: [
          {
            value: 'Yes',
            label: 'Yes',
            labelCn: '是',
            components: [
              {
                type: 'buttonSelect',
                title: 'Why are you willing to do the HPV test (Vaginal Swab) again? (can choose more than one)',
                titleCn: '您為什麼願意再次進行HPV測試(陰道拭子樣本)？(可以選擇多個答案)',
                isLarge: true,
                minSelect: 1,
                maxSelect: 6,
                configs: [
                  {
                    value: 'Sampling process is straightforward',
                    label: 'Sampling process is straightforward',
                    labelCn: '採樣過程簡便',
                  },
                  {
                    value: 'Sampling is convenient',
                    label: 'Sampling is convenient',
                    labelCn: '採樣方便',
                  },
                  {
                    value: 'I am confident in the accuracy of the sampling',
                    label: 'I am confident in the accuracy of the sampling',
                    labelCn: '我對採樣過程的準確性有信心',
                  },
                  {
                    value: 'I did not feel awkward during the sampling process',
                    label: 'I did not feel awkward during the sampling process',
                    labelCn: '我在採樣過程中沒有感到尷尬',
                  },
                  {
                    value: 'I did not feel uncomfortable/painful during the sampling process',
                    label: 'I did not feel uncomfortable/painful during the sampling process',
                    labelCn: '我在採樣過程中並沒有感到不適或疼痛',
                  },
                  {
                    value: 'I can save time by not having to visit the clinic',
                    label: 'I can save time by not having to visit the clinic',
                    labelCn: '我可以省去前往診所的時間',
                  },
                ],
              },
            ],
          },
          {
            value: 'No',
            label: 'No',
            labelCn: '否',
            components: [
              {
                type: 'buttonSelect',
                title: 'Why are you not willing to do the HPV test (Vaginal Swab) again? (can choose more than one)',
                titleCn: '您為什麼不願意再次進行HPV測試(陰道拭子樣本)？(可以選擇多個答案)',
                isLarge: true,
                minSelect: 1,
                maxSelect: 6,
                configs: [
                  {
                    value: 'Sampling process is difficult',
                    label: 'Sampling process is difficult',
                    labelCn: '採樣過程較困難',
                  },
                  {
                    value: 'Sampling process is cumbersome',
                    label: 'Sampling process is cumbersome',
                    labelCn: '採樣過程繁瑣',
                  },
                  {
                    value: 'I am not confident in the accuracy of the sampling',
                    label: 'I am not confident in the accuracy of the sampling',
                    labelCn: '我對採樣過程的準確性沒有信心',
                  },
                  {
                    value: 'I feel awkward during the sampling process',
                    label: 'I feel awkward during the sampling process',
                    labelCn: '我對採樣過程感到尷尬',
                  },
                  {
                    value: 'I feel uncomfortable / painful during the sampling process',
                    label: 'I feel uncomfortable / painful during the sampling process',
                    labelCn: '我在採樣過程中感到不適或疼痛',
                  },
                  {
                    value: "I'm worried that I might get hurt during the sampling process",
                    label: "I'm worried that I might get hurt during the sampling process",
                    labelCn: '我擔心採樣過程中會受傷',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: 'Sample Collection',
    nameCn: '樣本收集',
    components: [
      {
        type: 'sampleCollectionTime',
        title: 'Sample Collection Date and Time',
        titleCn: '樣本採集日期和時間',
        titleConfigs: [
          {
            title: 'Sample Collection Date ( YYYY / MM / DD )',
            titleCn: '樣本採集日期（YYYY/MM/DD）',
          },
          {
            title: 'Sample Collection Time ( HH : MM )',
            titleCn: '樣本採集時間（HH:MM）',
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'How did you learn about the INDICAID HPV Urine Test?',
        titleCn: '您從何得悉有關INDICAID妥析 HPV尿液測試的產品資訊?',
        isLarge: true,
        configs: [
          {
            value: 'Online Ads (E.g. Google Ads)',
            label: 'Online Ads (E.g. Google Ads)',
            labelCn: '網上廣告 (例如Google Ads)',
          },
          {
            value: 'Social media Ads (E.g. Facebook Ads or Instagram Ads)',
            label: 'Social media Ads (E.g. Facebook Ads or Instagram Ads)',
            labelCn: '社交媒體廣告 (例如Facebook廣告、Instagram廣告)',
          },
          {
            value: 'INDICAID Email Newsletter',
            label: 'INDICAID Email Newsletter',
            labelCn: '來自INDICAID妥析的電子郵件',
          },
          {
            value: 'Recommendations from KOLs or Social Media Influencers (Facebook/Instagram/Youtube)',
            label: 'Recommendations from KOLs or Social Media Influencers (Facebook/Instagram/Youtube)',
            labelCn: '意見領袖(KOL)/網紅(Influencer)於社交媒體(例如Facebook/Instagram/Youtube)推薦',
          },
          {
            value: 'Recommendations from medical professionals / professors',
            label: 'Recommendations from medical professionals / professors',
            labelCn: '專業人士推薦 (例如醫生、教授)',
          },
          {
            value: 'Retail Store Ads (E.g. flyers, posters, or displays in retail stores)',
            label: 'Retail Store Ads (E.g. flyers, posters, or displays in retail stores)',
            labelCn: '實體店廣告 (例如零售店內的傳單、海報、展示牌等)',
          },
          {
            value: 'Outdoor Ads',
            label: 'Outdoor Ads',
            labelCn: '戶外廣告',
          },
          {
            value: 'Search engine',
            label: 'Search engine',
            labelCn: '關鍵字搜索',
          },
          {
            value: 'Blogs',
            label: 'Blogs',
            labelCn: '網誌分享 (Blogs)',
          },
          {
            value: 'Others',
            label: 'Others',
            labelCn: '其他，請説明：',
            needTextInput: true,
          },
        ],
      },
      {
        type: 'buttonSelect',
        title: 'Why did you choose to use the INDICAID HPV Urine Test?(Please select 2 items)',
        titleCn: '為什麼選擇使用INDICAID妥析 HPV尿液測試?(請選擇2項)',
        isLarge: true,
        minSelect: 2,
        maxSelect: 2,
        configs: [
          {
            value:
              'I would like to learn about my cancer risk from a potential HPV infection (such as cervical cancer, vulvar cancer, vaginal cancer, penile cancer)',
            label:
              'I would like to learn about my cancer risk from a potential HPV infection (such as cervical cancer, vulvar cancer, vaginal cancer, penile cancer)',
            labelCn: '關注與人類乳頭瘤病毒（HPV）相關的癌症（例如子宮頸癌、外陰癌、陰道癌、陰莖癌等)風險',
          },
          {
            value: 'I regularly get HPV / cervical cancer screening tests',
            label: 'I regularly get HPV / cervical cancer screening tests',
            labelCn: '進行定期HPV / 子宮頸檢測',
          },
          {
            value: 'I am worried whether I have contracted an STD / HPV',
            label: 'I am worried whether I have contracted an STD / HPV',
            labelCn: '檢測是否感染性病/HPV',
          },
          {
            value:
              'Because this HPV screening test uses urine samples, is non-invasive, allows self-sampling, and is very private',
            label:
              'Because this HPV screening test uses urine samples, is non-invasive, allows self-sampling, and is very private',
            labelCn: '因INDICAID妥析HPV尿液測試的獨特性(尿液採樣 , 非入侵性/自行採樣, 私隱度高等)',
          },
          {
            value: 'Recommendations from medical professionals / professors',
            label: 'Recommendations from medical professionals / professors',
            labelCn: '醫生/醫護人員推薦',
          },
          {
            value: 'I am worried about infecting my partner',
            label: 'I am worried about infecting my partner',
            labelCn: '避免傳染給伴侶',
          },
          {
            value: 'My medical insurance covers HPV screening tests',
            label: 'My medical insurance covers HPV screening tests',
            labelCn: '醫療保險保障覆蓋HPV檢測',
          },
          {
            value: 'Others',
            label: 'Others',
            labelCn: '其他，請説明：',
            needTextInput: true,
          },
        ],
      },
    ],
  },
] as CompaginConfig['pageConfigs'];

export const compaginConfig: CompaginConfig = {
  compaginName: '0749',
  qa: {
    contentCn: `閣下獲邀參與本項臨床研究。在閣下決定參與本項臨床研究之前，請細閱本知情同意書，確保閣下知曉本項臨床研究之目的、程序及風險，以便閣下能夠作出知情的決定。若有任何疑慮或問題，請諮詢香港大學婦產科學系陳嘉倫教授(電話：2255-4265)。
研究課題：透過縱貫性研究，評估對於對定期子宮頸癌篩查意識較低的人群，進行尿液HPV檢測的可接受程度及對疾病控制的效果
這項研究的目的是什麼？
我們的目的是評估没有定期作子宮頸癌篩查的受試者分別接受尿液HPV檢測、自拭子HPV檢測和宮頸塗片檢測的可接受性和意願。 我們還旨在評估一年後HPV發病率的變化，從而評估尿液HPV檢測在疾病控制中的有效性。
為何會被選中？
您獲邀參加是因為您是一名年齡介乎18-80歲之間且有性經驗的女性。您去年參加了試點研究，在試點研究前3年內未接受宮頸篩查。並且您在過去沒有接受過子宮切除手術、 不曾患有子宮頸癌、現時沒有懷孕、且目前未接受子宮頸上皮内瘤病變或子宮頸癌的治療。
是否必需參加？
由您決定是否參加。如您決定參加，請簽署同意書。當然，您仍可隨時退出這項研究而不需要提供任何理由，這不會影響您所接受的護理標準。
如果參加，有什麼需要做？
如果您决定參加，您將首先從去年試點研究期間注册的非政府組織（NGO）處領取一份詳細列明了操作流程的研究簡介章程、一份尿液HPV自取樣套件和一份陰道拭子自取樣套件。 您還將被邀請到香港家庭計畫指導會（家計會）免費收集標準塗片。

對於尿液和陰道拭子樣本，您將接受自我取樣程序的指導，並需要自行進行取樣。樣本收集大約需要10分鐘，之後需歸還至非政府組織。在提交樣本前，您需要掃描說明書上的專屬二維碼以填寫樣本登記表格和您將需要填寫一份關於取樣方法的簡短問卷。 填寫此問卷可能需要約10分鐘的時間。採集的尿液樣本將送往Phase科學醫學實驗室進行檢測，而陰道拭子樣本將送往香港大學婦產科進行Cobas HPV檢測。

檢測結果將透過電子郵件或電話通知。若尿液HPV檢測或陰道拭子HPV檢測中出現異常結果，我們將再次聯系您，並將您轉介給家計會進行免費的標準塗片檢查。尿液樣本將被送往相達醫學檢驗實驗室進行檢測。陰道拭子樣本將被送往香港大學婦產科進行Cobas HPV檢測。

若您决定進行宮頸塗片HPV和細胞學檢測，您需自行向家計會預約。 所產生之費用皆已包含在研究中，您無需支付任何費用。 家計會的醫療專業人員將按照標準程式收集宮頸塗片樣本，預計持續時間為15-30分鐘。 細胞學結果和宮頸塗片樣本將被送往香港大學進行Cobas HPV檢測。 在完成採樣後，您需要填寫一份關於宮頸塗片檢查反饋的調查表，大約需要5分鐘的時間。 請立即完成並交還給診所工作人員。

從研究中收集的所有標本、您的個人資訊和數據將保存在相達醫學檢驗實驗室和香港大學婦產科學部門長達10年，以便未來以探索新的生物標記。
參加有什麼副作用？
没有副作用。
參加的弊處和風險是什麼？
除了自我取樣或由醫生進行的子宮頸取樣引起的可能不適，以及收​​集、遞送和由醫生取樣所花費的時間外，參與這項研究沒有可辨別的缺點或風險。
參加有什麼報酬？
您將額外獲得免費的尿液HPV檢測、免費的自我採集陰道拭子和免費的標準宮頸塗片作為子宮頸癌篩查。
參加有什麽研究費用及補償？
閣下參與此研究將不會獲得任何補償。為參與這項研究而進行的額外測試亦不須付出任何費用
參加研究的資料會被保密嗎？
所有研究收集的資料將只會用於研究目的，並會嚴格地保密。沒有您的允許，這項研究所發表的文章中將不會刊登您的名字。
研究的结果會怎麼處理？
結果分析後將在國際期刊和/或科研會議上發表。
誰組織並資助這項研究？
香港大學婦產科學系的研究人員將組織這項研究。該研究將由相達生物科技國際有限公司資助。
誰已審查這個研究？
香港大學及醫管局港島西醫院聯網研究倫理委員會。

更多資料的聯絡方式
無論現在或將來，只要您對這個研究有任何問題，我們都將詳細地解答。如果有任何問題，可以聯絡香港大學婦產科學系陳嘉倫教授(電話：2255-4265)。

如果您對身為研究參與者的權利有任何相關的疑問，您可以聯絡香港大學及醫管局港島西醫院聯網研究倫理委員會，電話號碼為 2255 4086。

非常感謝您加入這項研究。`,
    content: `You are invited to participate in this clinical study. Before you decide to participate in this clinical study, please read this informed consent form carefully to ensure that you understand the purpose, procedures, and risks of this clinical study so that you can make an informed decision. If you have any questions or concerns, please consult Professor Chen Ka Lun of the Department of Obstetrics and Gynecology, The University of Hong Kong (Tel: 2255-4265).
    Research Topic: Through a longitudinal study, evaluate the acceptability of urine HPV testing for people with low awareness of regular cervical cancer screening and its effect on disease control
    What is the purpose of this study?
    Our aim is to evaluate the acceptability and willingness of participants who have not had regular cervical cancer screening to undergo urine HPV testing, self-collected HPV testing, and cervical smear testing. We also aim to evaluate the change in HPV incidence one year later to assess the effectiveness of urine HPV testing in disease control.
    Why was I selected?
    You are invited to participate because `,
  },
  agreement: {
    title: '參加者同意書',
    titleCn: '參加者同意書',
    contentList: [
      {
        type: 'text',
        content: `項目名稱：透過縱貫性研究，評估對於對定期子宮頸癌篩查意識較低的人群，進行尿液HPV檢測的可接受程度及對疾病控制的效果
研究者姓名：陳嘉倫教授`,
        contentCn: `項目名稱：透過縱貫性研究，評估對於對定期子宮頸癌篩查意識較低的人群，進行尿液HPV檢測的可接受程度及對疾病控制的效果
研究者姓名：陳嘉倫教授`,
      },
      {
        type: 'checkbox',
        content: '1. 本人確認本人對於上述的研究已經閱讀並理解參考資料，並有機會提出問題。',
        contentCn: '1. 本人確認本人對於上述的研究已經閱讀並理解參考資料，並有機會提出問題。',
      },
      {
        type: 'checkbox',
        content:
          '2. 本人理解本人參加是項研究是自願並且在任何時候均可自由退出並不需提供任何理由，本人的醫療護理或法律權利不會受到影響。',
        contentCn:
          '2. 本人理解本人參加是項研究是自願並且在任何時候均可自由退出並不需提供任何理由，本人的醫療護理或法律權利不會受到影響。',
      },
      {
        type: 'checkbox',
        content:
          '3. 本人理解研究小組或管理當局的負責人員可以閱讀本人任何有關是項研究的醫療記錄。本人授權以上人員查閲本人的記錄。',
        contentCn:
          '3. 本人理解研究小組或管理當局的負責人員可以閱讀本人任何有關是項研究的醫療記錄。本人授權以上人員查閲本人的記錄。',
      },
      {
        type: 'checkbox',
        content: '4. 本人同意參與上述的研究。',
        contentCn: '4. 本人同意參與上述的研究。',
      },
      {
        type: 'text',
        content: `資料保密
有需要的話，每個研究參與者都有權利獲得其個人的數據以及公開報告的研究結果。
根據香港法律（特別是「個人資料（私隱）條例」，第486章），您有權對您的個人資料進行保密，如在本項研究中或與本項研究有關的個人資料的收集、保管、保留、管理、控制、使用（分析或比較）、在香港內外轉讓、不披露、消除和/或任何方式處理。如有任何問題，您可以諮詢個人資料私隱專員或致電到其辦公室（電話號碼：2827 2827），以適當監管或監督您個人資料保護，以便您能完全認識和瞭解確保遵守法律保護隱私資料的意義。

同意參與該項研究，您明確作出以下授權:
為了監督該項研究，授權主要研究者及其研究團隊和香港大學及醫管局港島西醫院聯網研究倫理委員根據本項研究和本知情同意書規定的方式獲得、使用並保留您的個人資料，並且為了檢查和核實研究資料的完整性、評估研究協定與相關要求的一致性，授權相關的政府機構（如香港衛生署）可獲得您個人資料。`,
        contentCn: `資料保密
有需要的話，每個研究參與者都有權利獲得其個人的數據以及公開報告的研究結果。
根據香港法律（特別是「個人資料（私隱）條例」，第486章），您有權對您的個人資料進行保密，如在本項研究中或與本項研究有關的個人資料的收集、保管、保留、管理、控制、使用（分析或比較）、在香港內外轉讓、不披露、消除和/或任何方式處理。如有任何問題，您可以諮詢個人資料私隱專員或致電到其辦公室（電話號碼：2827 2827），以適當監管或監督您個人資料保護，以便您能完全認識和瞭解確保遵守法律保護隱私資料的意義。

同意參與該項研究，您明確作出以下授權:
為了監督該項研究，授權主要研究者及其研究團隊和香港大學及醫管局港島西醫院聯網研究倫理委員根據本項研究和本知情同意書規定的方式獲得、使用並保留您的個人資料，並且為了檢查和核實研究資料的完整性、評估研究協定與相關要求的一致性，授權相關的政府機構（如香港衛生署）可獲得您個人資料。`,
      },

      {
        type: 'name-input',
        content: '',
        contentCn: '',
      },
    ],
  },
  pageConfigs,
};
