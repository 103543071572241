import { useMemoizedFn } from 'ahooks';
import { useState } from 'react';

export const useAddress = () => {
  const [pApartmentAddress, _setPApartmentAddress] = useState<string>(localStorage.getItem('pApartmentAddress') || '');
  const [pAddress, _setPAddress] = useState<string>(localStorage.getItem('pAddress') || '');
  const [pAddressRegion, _setPAddressRegion] = useState<string>(localStorage.getItem('pAddressRegion') || '');
  const [pAddressDistrict, _setPAddressDistrict] = useState<string | undefined>(
    localStorage.getItem('pAddressDistrict') || undefined,
  );
  const [pAddressCountry, _setPAddressCountry] = useState<string | undefined>(
    localStorage.getItem('pAddressCountry') || undefined,
  );

  const setPApartmentAddress = useMemoizedFn((v: string) => {
    localStorage.setItem('pApartmentAddress', v);
    _setPApartmentAddress(v);
  });

  const setPAddress = useMemoizedFn((v: string) => {
    localStorage.setItem('pAddress', v);
    _setPAddress(v);
  });

  const setPAddressRegion = useMemoizedFn((v: string) => {
    localStorage.setItem('pAddressRegion', v);
    _setPAddressRegion(v);
  });

  const setPAddressDistrict = useMemoizedFn((v: string | undefined) => {
    localStorage.setItem('pAddressDistrict', v || '');
    _setPAddressDistrict(v);
  });

  const setPAddressCountry = useMemoizedFn((v: string) => {
    localStorage.setItem('pAddressCountry', v);
    _setPAddressCountry(v);
  });

  return {
    pApartmentAddress,
    setPApartmentAddress,
    pAddress,
    setPAddress,
    pAddressRegion,
    setPAddressRegion,
    pAddressDistrict,
    setPAddressDistrict,
    pAddressCountry,
    setPAddressCountry,
  };
};
