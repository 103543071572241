import { MEDIA_SIZE } from '@consts/consts';
import FiledInput, { FiledInputPropsOnCheckValidType, FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';
// import { useSetFormValue, useGetFormValue } from '@states/formValue.state';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useEffect, useState } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import { useMemoizedFn } from 'ahooks';
import { t as _t } from 'i18next';
import enLocales from '@locales/en.json';
import { useAddress } from './tools';
import { useSetFormValue } from '@states/formValue.state';

const pAddressCountryOption = [
  {
    label: _t('collectionBootle.question20CountrySelect1'),
    value: enLocales.collectionBootle.question20CountrySelect1,
  },
];

const pAddressRegionOption = [
  {
    label: _t('collectionBootle.question20RegionSelect1'),
    value: enLocales.collectionBootle.question20RegionSelect1,
  },
  {
    label: _t('collectionBootle.question20RegionSelect2'),
    value: enLocales.collectionBootle.question20RegionSelect2,
  },
  {
    label: _t('collectionBootle.question20RegionSelect3'),
    value: enLocales.collectionBootle.question20RegionSelect3,
  },
];

export default function PickUpAdress(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  const setFormValue = useSetFormValue();
  // const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [pAddressDistrictOption, setPAddressDistrictOption] = useState<{ label: string; value: string }[]>();

  const {
    pApartmentAddress,
    setPApartmentAddress,
    pAddress,
    setPAddress,
    pAddressRegion,
    setPAddressRegion,
    pAddressDistrict,
    setPAddressDistrict,
    pAddressCountry,
    setPAddressCountry,
  } = useAddress();

  const isPAddressRegionError = !pAddressRegion && hasClickedNext;
  const isPAddressDistrictError = !pAddressDistrict && hasClickedNext;
  const isPAddressCountryError = !pAddressCountry && hasClickedNext;

  const handleCheckPAddress: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.AddressEmptyMessage') as string);
      } else if (val) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.AddressInvalidMessage') as string);
      }
    },
  );

  useEffect(() => {
    // 遍历option的选项
    let traversalPosition = 0;
    // 代表组别
    let group = 0;
    if (pAddressRegion) {
      if (pAddressRegion === enLocales.collectionBootle.question20RegionSelect1) {
        traversalPosition = 4;
        group = 1;
      } else if (pAddressRegion === enLocales.collectionBootle.question20RegionSelect2) {
        traversalPosition = 5;
        group = 2;
      } else if (pAddressRegion === enLocales.collectionBootle.question20RegionSelect3) {
        traversalPosition = 8;
        group = 3;
      }
      let arr: { label: string; value: string }[] = [];
      for (let i = 1; i <= traversalPosition; i++) {
        let obj: { label: string; value: string } = {
          label: t(`collectionBootle.region${group}District${i}`),
          value: enLocales.collectionBootle[
            `region${group}District${i}` as keyof typeof enLocales.collectionBootle
          ] as string,
        };
        arr.push(obj);
      }
      setPAddressDistrictOption(arr);
    } else {
      setPAddressDistrictOption(undefined);
    }
  }, [pAddressRegion, t]);

  useAddThirdStepCheckValidFunc(() => {
    if (!pAddress || !pAddressRegion || !pAddressCountry || !pAddressDistrict) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    let val: string | undefined = undefined;
    if (pAddress && pAddressRegion && pAddressCountry && pAddressDistrict) {
      val = `${
        pApartmentAddress ? `${pApartmentAddress},` : ''
      }${pAddress},${pAddressDistrict},${pAddressRegion},${pAddressCountry}`;
    }
    setFormValue({
      23: val,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pAddress, pAddressCountry, pAddressDistrict, pAddressRegion, pApartmentAddress]);

  /* Provide your delivery address */
  return (
    <div className="flex flex-col w-[100%] mt-[22px] lg:mt-[24px] lg:mb-[45px]">
      <FiledTitle
        title={t('collectionBootle.question20')}
        style={{
          marginBottom: isLg ? '23px' : '0.4878rem',
        }}
      />

      <FiledInput
        value={pApartmentAddress}
        onChange={setPApartmentAddress}
        title={t('collectionBootle.question20Title2')}
        placeholder={t('collectionBootle.question20Title2Placeholder')}
        filedInputClassName="mt-[0px]  lg:mt-[0px]"
        defaultValue={pApartmentAddress}
      />
      <FiledInput
        value={pAddress}
        onChange={setPAddress}
        title={t('collectionBootle.question20Title1')}
        placeholder={t('collectionBootle.question20Title1Placeholder')}
        filedInputClassName="mt-[29px]  lg:mt-[29px]"
        defaultValue={pAddress}
        onCheckValid={handleCheckPAddress}
        isSubmitCheck={hasClickedNext}
      />
      <div
        className={classNames(
          isPAddressRegionError && 'my-select-selector-empty-type',
          'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[0px] lg:mt-[29px] lg:mb-[0px]',
        )}
      >
        <FiledTitle title={t('collectionBootle.question20Title3')} />
        <Select
          onChange={(v) => {
            setPAddressRegion(v);
            setPAddressDistrict(undefined);
          }}
          style={{
            height: isLg ? '48px' : '1.1707rem',
            color: '#8B849B',
            fontWeight: 400,
            fontSize: isLg ? '14px' : '0.3415rem',
            lineHeight: isLg ? '24px' : '0.5854rem',
          }}
          placeholder={t('collectionBootle.question20Title3Placeholder') as string}
          value={pAddressRegion || undefined}
          defaultValue={pAddressRegion || undefined}
          // inputReadOnly={true}
          options={pAddressRegionOption}
        ></Select>
        {isPAddressRegionError && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              marginTop: isLg ? '0px' : '0rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </div>
      {pAddressRegion && (
        <div
          className={classNames(
            isPAddressDistrictError && 'my-select-selector-empty-type',
            'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[0px] lg:mt-[29px] lg:mb-[0px]',
          )}
        >
          <FiledTitle title={t('collectionBootle.question20Title5')} />
          <Select
            value={pAddressDistrict}
            onChange={setPAddressDistrict}
            style={{
              height: isLg ? '48px' : '1.1707rem',
              color: '#8B849B',
              fontWeight: 400,
              fontSize: isLg ? '14px' : '0.3415rem',
              lineHeight: isLg ? '24px' : '0.5854rem',
            }}
            placeholder={t('collectionBootle.question20Title5Placeholder') as string}
            defaultValue={pAddressDistrict}
            // inputReadOnly={true}
            options={pAddressDistrictOption}
          ></Select>
          {isPAddressDistrictError && (
            <MessageText
              helpText={t('collectionBootle.fieldsRequiredMessage') as string}
              status={'error'}
              style={{
                marginTop: isLg ? '0px' : '0rem',
              }}
              innerStyle={{
                justifyContent: 'flex-start',
                marginBottom: '0px',
              }}
            ></MessageText>
          )}
        </div>
      )}

      <div
        className={classNames(
          isPAddressCountryError && 'my-select-selector-empty-type',
          'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[0px] lg:mt-[29px] lg:mb-[0px]',
        )}
      >
        <FiledTitle title={t('collectionBootle.question20Title4')} />
        <Select
          onChange={setPAddressCountry}
          style={{
            height: isLg ? '48px' : '1.1707rem',
            color: '#8B849B',
            fontWeight: 400,
            fontSize: isLg ? '14px' : '0.3415rem',
            lineHeight: isLg ? '24px' : '0.5854rem',
          }}
          placeholder={t('collectionBootle.question20Title4Placeholder') as string}
          defaultValue={pAddressCountry || undefined}
          value={pAddressCountry || undefined}
          options={pAddressCountryOption}
        ></Select>
        {isPAddressCountryError && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={isPAddressCountryError ? 'error' : undefined}
            style={{
              marginTop: isLg ? '0px' : '0rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </div>
    </div>
  );
}
