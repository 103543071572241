import dayjs from 'dayjs';

export const setSampleExpireDate = (date: string) => {
  localStorage.removeItem('sampleExpireDate');
  localStorage.setItem('sampleExpireDate', date);
};
export const getSampleExpireDate = () => {
  const d = localStorage.getItem('sampleExpireDate');
  console.log('d', d, dayjs(d).format('YYYY/MM/DD'));
  if (d) return dayjs(d).format('YYYY/MM/DD');
  return undefined;
};

export const isADateExpired = (date: string) => {
  const d = localStorage.getItem('sampleExpireDate');
  if (!d) return false;
  return dayjs(d).isBefore(date);
};
