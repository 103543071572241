import FiledInput, { FiledInputPropsOnCheckValidType } from '@pages/CollectionBootle/components/FiledInput';
import { useFirstStepContext } from '@pages/CollectionBootle/stepsContext';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';
import { isEmail } from '@utils/index';
import { useMemoizedFn } from 'ahooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function EmailInput(props: { hasClickedNext: boolean }) {
  const isFirstSubmitCheck = !props.hasClickedNext;
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();
  const [email, setEmail] = useState(formValue[4] || '');
  const [doubleCheckEmail, setDoubleCheckEmail] = useState('');
  const onEmailChange = (v: string) => {
    setEmail(v);
    setFormValue({
      4: v,
    });
  };
  const onDoubleCheckEmailChange = (v: string) => {
    setDoubleCheckEmail(v);
  };

  const handleCheckEmail: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        // email可以为空
        setStatus(undefined);
        setHelpText('');
        // setStatus('error');
        // setHelpText(t('collectionBootle.EmailEmptyMessage') as string);
      } else if (isEmail(val)) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.EmailInvalidMessage') as string);
      }
    },
  );

  const handleCheckDoubleCheckEmail: FiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        // 允许为空
        setStatus(undefined);
        setHelpText('');
        // setStatus('error');
        // setHelpText(t('collectionBootle.DoubleCheckEmailEmptyError') as string);
      } else if (val && val === email) {
        setStatus('success');
        setHelpText('');
      } else {
        setStatus('error');
        setHelpText(t('collectionBootle.DoubleCheckEmailInvalidError') as string);
      }
    },
  );

  const { addCheckValidFunc } = useFirstStepContext();

  const checkValid = useMemoizedFn(async () => {
    if (email === '' && doubleCheckEmail === '') {
      return true;
    }
    if (isEmail(email) && email === doubleCheckEmail) {
      return true;
    }
    return false;
  });

  useEffect(
    () => {
      return addCheckValidFunc(checkValid);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <div className="mt-[30px] font-[500] text-[#25282B] text-[14px] leading-[28px] lg:mt-[40px] lg:text-[16px] lg:leading-[28px]">
        {t('collectionBootle.EmailReminder')}
      </div>
      {/* email */}
      <FiledInput
        value={email}
        onChange={onEmailChange}
        title={t('collectionBootle.question4')}
        placeholder={t('collectionBootle.question4Placeholder')}
        filedInputClassName="mt-[29px] lg:mt-[29px]"
        defaultValue={email}
        onCheckValid={handleCheckEmail}
        isSubmitCheck={isFirstSubmitCheck}
        fontFamilyValue={'Montserrat'}
      />
      {/* doubleCheckEmail */}
      <FiledInput
        value={doubleCheckEmail}
        onChange={onDoubleCheckEmailChange}
        title={t('collectionBootle.DoubleCheckEmailTitle')}
        placeholder={t('collectionBootle.question4Placeholder') as string}
        filedInputClassName="mt-[12px] lg:mt-[12px]"
        defaultValue={doubleCheckEmail}
        onCheckValid={handleCheckDoubleCheckEmail}
        isSubmitCheck={isFirstSubmitCheck}
        fontFamilyValue={'Montserrat'}
      />
    </>
  );
}
