import { useTranslation } from 'react-i18next';
import CollectionHeader from '../components/CollectionHeader';
import { StepBox } from '../components/StepBox';
import { useParams } from 'react-router-dom';
import { CurPage } from '@consts/enums';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import { useState } from 'react';
import { CheckValidFunc, SecondStepContext } from '../stepsContext';
import CustomSteps from '../components/CustomSteps';
import { useCheckValidAllFirstStep, useGetFormValue } from '@states/formValue.state';
import classNames from 'classnames';
import CollectionBootleButton from '../components/CollectionBootleButton';
import MessageText from '../components/MessageText';
import { useLocalNavigate } from 'src/router/routerTools';
import SecondAgreement from './components/SecondAgreement';
import { useMemoizedFn } from 'ahooks';
import useCheckSampleId from '../hooks/useCheckSampleId';

export default function SecondStep() {
  const formValue = useGetFormValue();
  const [t] = useTranslation();
  const collectionBottleId = useParams().collectionBottleId;
  const [hasClickedNext, setHasClickedNext] = useState(false);
  const navigate = useLocalNavigate();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  isLg;
  const [checkValidFuncs, setCheckValidFuncs] = useState<CheckValidFunc[]>([]);
  const addCheckValidFunc = useMemoizedFn((func: CheckValidFunc) => {
    setCheckValidFuncs((funcs) => {
      return [...funcs, func];
    });

    return () => {
      setCheckValidFuncs((funcs) => {
        return funcs.filter((f) => f !== func);
      });
    };
  });
  const [hasError, setHasError] = useState(false);
  const checkAllValid = async () => {
    const results = await Promise.all(checkValidFuncs.map((func) => func()));
    return results.every((result) => result);
  };
  const [loading, setLoading] = useState(false);
  const onCheckValid = async () => {
    setHasClickedNext(true);
    setLoading(true);
    try {
      const results = await checkAllValid();
      if (results) {
        navigate(`/thirdStep/${collectionBottleId}`);
      }
      setHasError(!results);
    } catch (e) {
      console.error(e);
      setHasError(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // 检查第一步的信息是不是都填了
  useCheckValidAllFirstStep();
  useCheckSampleId();

  return (
    <SecondStepContext.Provider
      value={{
        checkValidFuncs,
        addCheckValidFunc,
      }}
    >
      <div>
        <CollectionHeader />
        <StepBox step={2}>
          <div className="flex flex-col lg:mt-[40px] mt-[18px]"></div>
          <CustomSteps curPage={CurPage.MedicalHistory} />
          <div
            className={classNames(
              formValue[5] === 'Male' ? 'mt-[0px] lg:mt-[0px]' : 'mt-[43px] lg:mt-[74px]',
              'flex flex-col w-[100%] ',
            )}
          >
            {/* <FemalePapPart hasClickedNext={hasClickedNext} /> */}
            {/* <MaleHpvPart /> */}
            {/* <HasTestedHpvPart hasClickedNext={hasClickedNext} /> */}
            <SecondAgreement hasClickedNext={hasClickedNext} />
            <div className="flex justify-between mt-[50px] lg:mt-[58px] relative">
              {hasError && (
                <MessageText
                  helpText={t('collectionBootle.clickNextErrorMessage') as string}
                  status={'error'}
                  style={{
                    position: 'absolute',
                    right: '0px',
                    top: isLg ? '-30px' : '-0.7317rem',
                  }}
                ></MessageText>
              )}
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-[170px] h-[50px] mb-[63px] md:w-[170px] md:h-[50px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px]"
                value={t('collectionBootle.Back')}
                status="check"
                onClick={() => {
                  navigate('/firstStep/' + collectionBottleId);
                }}
              ></CollectionBootleButton>
              <CollectionBootleButton
                htmlType="submit"
                buttonClassName="w-[170px] h-[50px] mb-[63px]  md:w-[170px] md:h-[50px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px]"
                disable={false}
                value={t('collectionBootle.Next')}
                status={'check'}
                onClick={onCheckValid}
                loading={loading}
              ></CollectionBootleButton>
            </div>
          </div>
        </StepBox>
      </div>
    </SecondStepContext.Provider>
  );
}
