import { MEDIA_SIZE } from '@consts/consts';
import CnFiledInput, { CnFiledInputPropsOnCheckValidType } from '@pages/CollectionBootle/components/CnFiledInput';
import CollectionDatePicker from '@pages/CollectionBootle/components/CollectionDatePicker';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';
import { isLowVersionChangeDatePicker } from '@utils/index';
import classNames from 'classnames';
import { t as _t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import PickUpTime from './PickUpTime';
import PickUpAdress from './PickUpAdress';

const isLowVersion = isLowVersionChangeDatePicker();

const createCheckDateFunc = (htxt: string): CnFiledInputPropsOnCheckValidType => {
  return (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
    if (val === '' && !isFiledInputSubmitCheck) {
      setStatus(undefined);
      setHelpText('');
    } else if (val === '' && isFiledInputSubmitCheck) {
      setStatus('error');
      setHelpText(htxt);
    } else {
      setStatus('success');
      setHelpText('');
    }
  };
};

const dayValidFunc = createCheckDateFunc(_t('collectionBootle.DateNotBeEmpty'));
const monthValidFunc = createCheckDateFunc(_t('collectionBootle.MonthCanNotBeEmpty'));
const yearValidFunc = createCheckDateFunc(_t('collectionBootle.YearCanNotBeEmpty'));

export default function PickUpDate(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [pickUpDate, setPickUpDate] = useState<string | undefined>(formValue[20] || undefined);
  const [pickUpDay, setPickUpDay] = useState<string>('');
  const [pickUpMonth, setPickUpMonth] = useState<string>('');
  const [pickUpYear, setPickUpYear] = useState<string>('');

  const isError = !pickUpDate && hasClickedNext;

  useEffect(() => {
    if (pickUpDay && pickUpMonth && pickUpYear) {
      setPickUpDate(`${pickUpYear}/${pickUpMonth}/${pickUpDay}`);
      setFormValue({
        20: `${pickUpYear}/${pickUpMonth}/${pickUpDay}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpDay, pickUpMonth, pickUpYear]);

  useAddThirdStepCheckValidFunc(() => {
    if (!pickUpDate) {
      return false;
    }
    return true;
  });

  const SubmitMethod = formValue[19];
  useEffect(() => {
    if (formValue[19] !== 'PickUp') {
      setPickUpDate(undefined);
      setPickUpDay('');
      setPickUpMonth('');
      setPickUpYear('');
      setFormValue({
        20: undefined,
        21: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SubmitMethod]);

  if (formValue[19] !== 'PickUp') return null;

  return (
    <div className="flex flex-col">
      <p
        className="font-[600] text-[16px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[20px] flex lg:leading-[24px] lg:tracking-[0.4px] "
        style={{
          marginTop: isLg ? '0px' : '0.7073rem',
          marginBottom: isLg ? '0px' : '0rem',
        }}
      >
        {t('collectionBootle.question18InfoTitle')}
      </p>

      <FiledTitle
        title={t('collectionBootle.question18InfoText3') as string}
        style={{
          marginTop: isLg ? '29px' : '0.7073rem',
          marginBottom: isLg ? '29px' : '0.7073rem',
        }}
      />
      {/* Choose your preferred pick-up date */}
      {isLowVersion ? (
        <div className="flex flex-col mt-[29px] lg:mt-[45px]">
          {/* pickUpLocationsTitleSerial */}
          <FiledTitle
            style={{
              marginTop: '0px',
            }}
            title={t('collectionBootle.question18')}
          />
          <div className="flex justify-between">
            <CnFiledInput
              style={{
                width: '30%',
                margin: isLg ? '0px' : '0px',
              }}
              value={pickUpDay}
              onChange={setPickUpDay}
              placeholder={t('collectionBootle.DatePlaceHolder')}
              onCheckValid={dayValidFunc}
              isSubmitCheck={!hasClickedNext}
              number={true}
            />
            <CnFiledInput
              style={{
                width: '30%',
                margin: isLg ? '0px' : '0px',
              }}
              value={pickUpMonth}
              onChange={setPickUpMonth}
              placeholder={t('collectionBootle.MonthPlaceHolder')}
              onCheckValid={monthValidFunc}
              isSubmitCheck={!hasClickedNext}
              number={true}
            />
            <CnFiledInput
              style={{
                width: '30%',
                margin: isLg ? '0px' : '0px',
              }}
              value={pickUpYear}
              onChange={setPickUpYear}
              placeholder={t('collectionBootle.YearPlaceHolder')}
              onCheckValid={yearValidFunc}
              isSubmitCheck={!hasClickedNext}
              number={true}
            />
          </div>
        </div>
      ) : (
        <CollectionDatePicker
          title={t('collectionBootle.question18') as string}
          onChange={(_, dateString) => {
            console.log('dateString', dateString);
            setPickUpDate(dateString);

            setFormValue({
              20: dateString ?? undefined,
            });
          }}
          // collectionDatePickerClassName={'mt-[29px] lg:mt-[45px]'}
          collectionDatePickerClassName={classNames(
            isError && 'my-select-selector-empty-type',
            'mt-[29px] lg:mt-[45px]',
          )}
          style={{
            marginTop: '0px',
          }}
          placeholder={t('collectionBootle.question18Placeholder') as string}
          defaultValue={pickUpDate}
          isPickUpTime={true}
          forceDisabledDate={true}
        />
      )}

      {isError && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: isLg ? '0px' : '0rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
      <PickUpTime hasClickedNext={hasClickedNext} />
      <PickUpAdress hasClickedNext={hasClickedNext} />
    </div>
  );
}
