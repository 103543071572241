import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { MEDIA_SIZE, VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';
import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import website from '@configs/website.json';
import { useLSCountDown as useCountDown } from 'src/hooks/tools/countDown';
import { post } from '@utils/request';
import { message } from 'antd';
import CnFiledInput, { CnFiledInputPropsOnCheckValidType } from '@pages/CollectionBootle/components/CnFiledInput';
import { useMemoizedFn } from 'ahooks';
import { useFirstStepContext } from '@pages/CollectionBootle/stepsContext';
import TextInput, { TextInputStatus } from '../../../ExportDoctorExcel/components/TextInput';
import { useSetFormValue } from '@states/formValue.state';

export default function UserPhoneInput(props: { hasClickedNext: boolean }) {
  const setFormValue = useSetFormValue();

  const isFirstSubmitCheck = !props.hasClickedNext;
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();

  const [txt, setTxt] = useState<string>('');
  const timerId = website.can_resend_after;

  const { count, startCount } = useCountDown(VERIFY_CODE_WAITING_SECONDS, timerId);
  const [loading, setLoading] = useState(false);
  const apiSendCode = () => {
    if (!isPhoneNumberValid) {
      return;
    }
    setLoading(true);
    post('/user/send-otp', {
      type: 'phone',
      phone: phoneNumber,
    })
      .then(() => {
        startCount();
      })
      .catch((e) => {
        message.error(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem(`timer:${website.can_resend_after}`)) {
      // setTxt(count === 0 ? '重新发送' : `重新发送 (${count}s)`);
      setTxt(
        count === 0
          ? (t('collectionBootle.ResendOTP') as string)
          : (t('collectionBootle.ResendCount', { count: count }) as string),
      );
    } else {
      // 获取验证码
      setTxt(t('collectionBootle.SendOTP') as string);
    }
  }, [count, t]);

  const [otp, setOtp] = useState('');

  const onOtpChange = (val: string) => {
    const newValue = val;
    setOtp(newValue);
  };
  const handleCheckOtp: CnFiledInputPropsOnCheckValidType = useMemoizedFn((val, { setStatus, setHelpText }) => {
    if (val === '' && isFirstSubmitCheck) {
      setStatus(undefined);
      setHelpText('');
    } else if (val === '' && !isFirstSubmitCheck) {
      setStatus('error');
      setHelpText(t('collectionBootle.OTPEmptyError') as string);
    } else {
      setStatus(undefined);
      setHelpText('');
    }
  });

  const isButtonDisabled = useMemo(() => {
    if (!isPhoneNumberValid) {
      return true;
    }
    return count !== 0;
  }, [count, isPhoneNumberValid]);
  const [errorStatus, setErrorStatus] = useState<TextInputStatus>();
  const [errorHelpText, setErrorHelpText] = useState<string>();

  const { addCheckValidFunc } = useFirstStepContext();

  const checkValid = useMemoizedFn(async () => {
    if (!isPhoneNumberValid) {
      return false;
    }
    if (otp === '') {
      return false;
    }

    return post('/user/otp-code-validation', {
      phone: phoneNumber,
      code: otp,
    })
      .then(() => {
        setErrorStatus(undefined);
        setErrorHelpText('');
        return true;
      })
      .catch((e) => {
        setErrorStatus('error');
        setErrorHelpText(e.response.data.message);

        return false;
      });
  });

  useEffect(() => {
    return addCheckValidFunc(checkValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (otp === '' && !isFirstSubmitCheck) {
      setErrorStatus('error');
      setErrorHelpText(t('collectionBootle.OTPEmptyError') as string);
    }
  }, [isFirstSubmitCheck, otp, t]);

  return (
    <div className="lg:w-[100%]  lg:mt-[29px] mt-[29px]">
      <FiledTitle title={t('collectionBootle.question3')} />
      <div className={classNames('mb-[8px] lg:mb-[23px]', 'flex  lg:justify-between')}>
        <PhoneNumberInput
          wrapperClassName="h-[48px] lg:rounded-[8px] rounded-[6px] w-[100%] px-[15px] text-[16px]  border-[2px] flex items-center lg:text-[14px] lg:w-[100%] lg:h-[48px] lg:h-[48px]  lg:px-[15px]"
          value={phoneNumber}
          onChange={(val: string) => {
            setFormValue({
              3: val,
            });
            setPhoneNumber(val);
          }}
          onIsValidChange={handleIsPhoneNumberValidChange}
          isSubmitCheck={!isFirstSubmitCheck}
          defaultValue={phoneNumber}
          outsideStyle={{
            width: isLg ? '70.52%' : undefined,
          }}
          style={{
            width: isLg ? '100%' : undefined,
          }}
          onlyShowHkSelect={false}
        />
        <CollectionBootleButton
          htmlType="submit"
          buttonClassName="w-[106px] ml-[5px] h-[48px] mb-[0px] md:w-[212px] md:h-[48px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px] md:ml-[16px]"
          value={txt}
          disable={isButtonDisabled}
          onClick={apiSendCode}
          sectionClassName={isLg ? 'flex justify-end h-[48px] md:h-[48px]' : 'h-[48px] md:h-[48px]'}
          style={{
            padding: '0px',
          }}
          loading={loading}
          status={isButtonDisabled ? 'disabled' : 'default'}
        ></CollectionBootleButton>
      </div>
      <CnFiledInput
        style={{
          width: isLg ? undefined : undefined,
          margin: isLg ? '0px' : undefined,
        }}
        value={otp}
        onChange={onOtpChange}
        placeholder={t('collectionBootle.OtpPlaceholder')}
        onCheckValid={handleCheckOtp}
        isSubmitCheck={isFirstSubmitCheck}
        isOtpSubmitCheck={isFirstSubmitCheck}
        fontFamilyValue={'Montserrat'}
        subHelpText={t('collectionBootle.OtpSubHelpText') || undefined}
      />
      {errorStatus === 'error' && otp && (
        <TextInput
          isOnlyRenderErrorText={true}
          sectionClassName="w-full lg:w-full"
          status={errorStatus}
          inputClassName={
            'h-[48px] text-[14px] leading-[24px] tracking-[0.2px] text-[400] w-[100%] lg:text-[14px] lg:leading-[24px]  lg:w-[100%] md:h-[48px] lg:h-[48px] lg:tracking-[0.2px] lg:mr-[17px]'
          }
          helpText={errorHelpText}
        ></TextInput>
      )}
    </div>
  );
}
