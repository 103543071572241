import { CurPage } from '@consts/enums';
import { useSteps } from '@pages/CollectionBootle/components/collectionConst';
import { Steps } from 'antd';
import classNames from 'classnames';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';

export default function CustomSteps(props: { curPage: CurPage }) {
  const isZhHKLanguage = useIsZhHKLanguage();
  const steps = useSteps();
  return (
    <div
      className={classNames(
        isZhHKLanguage ? 'w-[100vw] left-[-28px] p-[0px_0px_0px_0px]' : 'w-[105vw] left-[-50px] p-[0px_25px_0px_25px]',
        'relative lg:w-[58.88vw] lg:left-[-1.15942rem] lg:p-0',
      )}
    >
      <Steps
        className={classNames('lg:mt-[0px]', 'mt-[18px] w-[100%] lg:w-[120%]')}
        // TODO horizontal失效的问题
        direction="horizontal"
        responsive={false}
        progressDot
        current={props.curPage}
        items={steps}
      />
    </div>
  );
}
