import { PhoneNumberInputWithNativeSelect } from 'react-phonenumber-text-input';
import { MEDIA_SIZE, VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';
import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import website from '@configs/website.json';
import { useLSCountDown as useCountDown } from 'src/hooks/tools/countDown';
import { post } from '@utils/request';
import { message } from 'antd';
import { useMemoizedFn } from 'ahooks';
import { BasePageComponentProps, PageComponentConfigMap } from './BaseComponent/PageComponentMap';
import { TextInputStatus } from '@components/TextInput/TextInput';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import FiledInput from './BaseComponent/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';

type PhoneInputProps = BasePageComponentProps & PageComponentConfigMap['phone'];

export default function UserPhoneInput(props: PhoneInputProps) {
  const { pageValues, setPageValue } = usePageContext();

  const value = pageValues[props.title]?.value;

  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const { phoneNumber, isPhoneNumberValid, setPhoneNumber, handleIsPhoneNumberValidChange } =
    usePhoneNumberVerify(value);

  const [txt, setTxt] = useState<string>('');
  const timerId = website.can_resend_after;

  const { count, startCount } = useCountDown(VERIFY_CODE_WAITING_SECONDS, timerId);
  const [loading, setLoading] = useState(false);
  const apiSendCode = () => {
    if (!isPhoneNumberValid) {
      return;
    }
    setLoading(true);
    post('/user/send-otp', {
      type: 'phone',
      phone: value,
    })
      .then(() => {
        startCount();
      })
      .catch((e) => {
        message.error(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem(`timer:${website.can_resend_after}`)) {
      // setTxt(count === 0 ? '重新发送' : `重新发送 (${count}s)`);
      setTxt(
        count === 0
          ? (t('collectionBootle.ResendOTP') as string)
          : (t('collectionBootle.ResendCount', { count: count }) as string),
      );
    } else {
      // 获取验证码
      setTxt(t('collectionBootle.SendOTP') as string);
    }
  }, [count, t]);

  const [otp, setOtp] = useState('');

  const onOtpChange = (val: string) => {
    const newValue = val;
    setOtp(newValue);
  };

  const isButtonDisabled = useMemo(() => {
    if (!isPhoneNumberValid) {
      return true;
    }
    return count !== 0;
  }, [count, isPhoneNumberValid]);
  const [errorStatus, setErrorStatus] = useState<TextInputStatus>();
  const [errorHelpText, setErrorHelpText] = useState<string>();

  const checkValid = useMemoizedFn(async () => {
    if (!phoneNumber) {
      handleIsPhoneNumberValidChange(false);
    }

    if (!isPhoneNumberValid) {
      setErrorStatus('error');
      if (otp === '') {
        setErrorStatus('error');
        setErrorHelpText(t('collectionBootle.OTPEmptyError') as string);
        return false;
      }
      return false;
    }
    if (otp === '') {
      setErrorStatus('error');
      setErrorHelpText(t('collectionBootle.OTPEmptyError') as string);
      return false;
    }

    return post('/user/otp-code-validation', {
      phone: value,
      code: otp,
    })
      .then(() => {
        setErrorStatus(undefined);
        setErrorHelpText('');
        return true;
      })
      .catch((e) => {
        setErrorStatus('error');
        setErrorHelpText(e.response.data.message);

        return false;
      });
  });

  useAddCheckValidFunc(checkValid);

  return (
    <div className="lg:w-[100%]  lg:mt-[29px] mt-[29px]">
      <FiledTitle title={t('collectionBootle.question3')} />
      <div className={classNames('mb-[8px] lg:mb-[23px]', 'flex  lg:justify-between')}>
        <PhoneNumberInputWithNativeSelect
          style={{
            flex: '1',
            borderColor: isPhoneNumberValid ? '#D5D4DC' : 'rgb(221, 82, 76)',
            borderWidth: '2px',
            minWidth: '0px',
            backgroundColor: isPhoneNumberValid ? '#ffffff' : 'rgb(251, 239, 239)',
          }}
          className="h-[48px] md:h-[48px]"
          countries={['HK', 'CN']}
          onChange={(v, { fullValue, valid }) => {
            setPhoneNumber(v);
            if (valid) {
              setErrorStatus(undefined);
              setPageValue(props.title, fullValue);
            }

            handleIsPhoneNumberValidChange(valid);
          }}
          value={phoneNumber}
        />
        <CollectionBootleButton
          htmlType="submit"
          buttonClassName="min-w-[106px] ml-[5px] h-[48px] mb-[0px] md:w-[212px] md:h-[48px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:leading-[24px] md:ml-[16px]"
          value={txt}
          disable={isButtonDisabled}
          onClick={apiSendCode}
          sectionClassName={isLg ? 'flex justify-end h-[48px] md:h-[48px]' : 'h-[48px] md:h-[48px]'}
          style={{
            padding: '0px',
          }}
          loading={loading}
          status={isButtonDisabled ? 'disabled' : 'default'}
        ></CollectionBootleButton>
      </div>

      {!isPhoneNumberValid && (
        <MessageText
          helpText={t('collectionBootle.PhoneNumberValidText') as string}
          status={'error'}
          style={{
            marginTop: isLg ? '11px' : '0.2683rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
      <FiledInput
        status={errorStatus}
        value={otp}
        onChange={onOtpChange}
        placeholder={t('collectionBootle.OtpPlaceholder')}
        fontFamilyValue={'Montserrat'}
        subHelpText={t('collectionBootle.OtpSubHelpText') || undefined}
      />

      {errorStatus === 'error' && (
        <MessageText
          helpText={errorHelpText}
          status={errorStatus}
          style={{
            marginTop: isLg ? '11px' : '0.2683rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </div>
  );
}
