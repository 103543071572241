import { MEDIA_SIZE } from '@consts/consts';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import { useState } from 'react';

export default function PickUpTime(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [pickTime, setPickTime] = useState<string>(formValue[21] || undefined);

  const isError = !pickTime && hasClickedNext;
  const clearCollectionDateAndTime = () => {
    setFormValue({
      24: undefined,
      25: undefined,
    });
  };

  useAddThirdStepCheckValidFunc(() => {
    if (!pickTime) {
      return false;
    }
    return true;
  });

  /* Choose your preferred pick-up time */
  return (
    <div className="flex flex-col w-[100%] mt-[40px] lg:mt-[45px]">
      <FiledTitle title={t('collectionBootle.question19')} />
      <CollectionBootleButton
        htmlType="submit"
        buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
        value={'09:00-13:00'}
        status={isError ? 'emptyError' : pickTime === '09:00-13:00' ? 'check' : 'unchecked'}
        onClick={() => {
          const val = '09:00-13:00';
          setPickTime(val);
          setFormValue({
            21: val,
          });
          clearCollectionDateAndTime();
        }}
      ></CollectionBootleButton>
      {/* <CollectionBootleButton
    htmlType="submit"
    buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
    value={'12:00-15:00'}
    status={
      forceThirdSubmitShowError(pickTime) ? 'emptyError' : pickTime === '12:00-15:00' ? 'check' : 'unchecked'
    }
    onClick={() => {
      const val = '12:00-15:00';
      setPickTime(val);
      const newFormValue = {
        ...formValue,
        21: val,
      };
      setFormValue(newFormValue);
      clearCollectionDateAndTime(newFormValue);
    }}
  ></CollectionBootleButton> */}
      {/* <CollectionBootleButton
    htmlType="submit"
    buttonClassName="w-full h-[38px] mb-[18px]  md:w-[26.66vw] md:h-[38px] md:text-[14px] md:mt-[0px] md:mb-[21px]"
    value={'15:00-18:00'}
    status={
      forceThirdSubmitShowError(pickTime) ? 'emptyError' : pickTime === '15:00-18:00' ? 'check' : 'unchecked'
    }
    onClick={() => {
      const val = '15:00-18:00';
      setPickTime(val);
      const newFormValue = {
        ...formValue,
        21: val,
      };
      setFormValue(newFormValue);
      clearCollectionDateAndTime(newFormValue);
    }}
  ></CollectionBootleButton> */}
      {isError && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: isLg ? '0px' : '0px',
            position: 'relative',
            top: isLg ? '-15px' : '-0.3659rem',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </div>
  );
}
