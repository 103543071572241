import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import CollectionHeader from '@pages/CollectionBootle/components/CollectionHeader';
import FiledInput from '@pages/CollectionBootle/components/FiledInput';
import { Button, message } from 'antd';
import { useState } from 'react';
import { useLocalNavigate } from 'src/router/routerTools';
import { post } from '@utils/request';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function SpecialCode() {
  const [code, setCode] = useState<string>('');
  const navigate = useLocalNavigate();
  const sampleId = useParams().collectionBottleId;

  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  const checkSpecialCode = async () => {
    try {
      setLoading(true);
      await post('/hpv/checkSpecialCodeExist', {
        code,
      }).then((res) => {
        if (!res.isExist) {
          message.error('The code has not been found');
          return;
        } else {
          localStorage.setItem('specialCode', code);
          // navigate('/firstStep/' + sampleId);
          navigate(`/agreements/${sampleId}?c=${code}`);
        }
      });
    } catch (err: any) {
      const responseMessage = err?.response?.data?.message;
      const errorMessage =
        (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || t('collectionBootle.error');
      message.error(errorMessage);
    }
    setLoading(false);
  };

  return (
    <div className="w-[100vw] h-[100vh] bg-[#F6F8FB]">
      <CollectionHeader />
      <div className="pl-[25px] pr-[25px] pt-[50px] w-[364px] h-[412px] md:w-[1028px] md:h-[457px] md:pl-[198px] md:pr-[220px] md:pt-[60px] bg-[#ffffff] m-auto">
        <p className="text-[14px] md:text-[28px] font-[600] md:mb-[45px] mb-[45px]">HPV Urine Test Resgistration</p>
        <FiledInput
          title={'Access Code (if any)'}
          placeholder={'please input your code'}
          value={code}
          onChange={(v) => {
            setCode(v.trim());
          }}
        />
        <CollectionBootleButton
          loading={loading}
          htmlType="submit"
          buttonClassName="md:w-[326px] md:h-[50px] md:mt-[45px] mt-[45px] md:mb-[14px] h-[50px] md:text-[16px] w-full"
          value={'Submit'}
          disable={!code}
          status={!code ? 'disabled' : 'default'}
          onClick={checkSpecialCode}
        ></CollectionBootleButton>
        <Button
          onClick={() => {
            navigate(`/agreements/${sampleId}`);
          }}
          className="md:w-[326px] md:h-[50px] h-[50px]   w-full"
        >
          <span className="text-[#2DBDB6] md:text-[16px]">Skip this step</span>
        </Button>
      </div>
    </div>
  );
}
