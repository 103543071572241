import { MEDIA_SIZE } from '@consts/consts';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { FiledTitle } from './BaseComponent/FiledInput';
import CollectionDatePicker from '@pages/CollectionBootle/components/CollectionDatePicker';
import classNames from 'classnames';
import { Select } from 'antd';
import { BasePageComponentProps, PageComponentConfigMap } from './BaseComponent/PageComponentMap';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import { useState } from 'react';

const getTimeOptionValue = () => {
  const options = [];

  // 生成选项
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      options.push({
        label: time,
        value: time,
      });
    }
  }
  return options;
};
const timeOptionValue = getTimeOptionValue();

type SampleCollectTimeSelectProps = BasePageComponentProps & PageComponentConfigMap['sampleCollectionTime'];

export default function SampleCollectTimeSelect(props: SampleCollectTimeSelectProps) {
  const { pageValues, setPageValue } = usePageContext();

  const dateValue = pageValues[props.titleConfigs[0].title]?.value;
  const timeValue = pageValues[props.titleConfigs[1].title]?.value;

  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [isDateError, setIsDateError] = useState(false);
  const [isTimeError, setIsTimeError] = useState(false);

  useAddCheckValidFunc(() => {
    let err1 = false;
    let err2 = false;

    if (!dateValue) {
      err1 = true;
      setIsDateError(true);
    }

    if (!timeValue) {
      err2 = true;
      setIsTimeError(true);
    }

    return !(err1 || err2);
  });

  return (
    <>
      <p
        className="font-[600] text-[16px] text-[#25282B] leading-[20px] tracking-[0.4px] lg:mb-[8px] mb-[8px] lg:text-[20px] flex lg:leading-[24px] lg:tracking-[0.4px] "
        style={{
          marginTop: isLg ? '45px' : '0.7073rem',
          marginBottom: isLg ? '0px' : '0rem',
        }}
      >
        {t('collectionBootle.question17InfoTitle')}
      </p>
      <FiledTitle
        title={t('collectionBootle.question17InfoText')}
        style={{
          marginTop: isLg ? '29px' : '0.7073rem',
          marginBottom: isLg ? '0px' : '0rem',
        }}
      />

      <CollectionDatePicker
        value={dateValue || undefined}
        title={t('collectionBootle.question21') as string}
        onChange={(d) => {
          setIsDateError(false);
          setPageValue(props.titleConfigs[0].title, d?.format('YYYY/MM/DD') || '');
        }}
        placeholder={t('collectionBootle.question21Placeholder') as string}
        collectionDatePickerClassName={classNames(
          isDateError && 'my-select-selector-empty-type',
          'mt-[29px] lg:mt-[45px]',
        )}
      />

      <div
        className={classNames(
          isTimeError && 'my-select-selector-empty-type',
          'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[46px] lg:mt-[45px] lg:mb-[59px]',
        )}
      >
        <FiledTitle title={t('collectionBootle.question22')} />
        <Select
          value={timeValue || undefined}
          onChange={(v) => {
            setIsTimeError(false);
            setPageValue(props.titleConfigs[1].title, v);
          }}
          style={{
            height: isLg ? '48px' : '1.1707rem',
            color: '#8B849B',
            fontWeight: 400,
            fontSize: isLg ? '14px' : '0.3415rem',
            lineHeight: isLg ? '24px' : '0.5854rem',
          }}
          placeholder={t('collectionBootle.question22Placeholder') as string}
          options={timeOptionValue}
        ></Select>
      </div>
    </>
  );
}
