import { useGetFormValue, useSetFormValue } from '@states/formValue.state';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function useCheckSampleId() {
  const collectionBottleId = useParams().collectionBottleId;
  const formValue = useGetFormValue();
  const setFormValue = useSetFormValue();
  const localSampleId = formValue[0];

  useEffect(() => {
    if (localSampleId !== collectionBottleId) {
      setFormValue({
        0: collectionBottleId,
      });
      localStorage.setItem('collectionBootleId', JSON.stringify(collectionBottleId || ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionBottleId, localSampleId]);
}
