import { useTranslation } from 'react-i18next';
import CollectionHeader from '../components/CollectionHeader';
import { StepBox } from '../components/StepBox';
import { useParams } from 'react-router-dom';
import { CurPage } from '@consts/enums';
import UserPhoneInput from './components/UserPhoneInput';
import CustomSteps from '../components/CustomSteps';
import CollectionBootleButton from '../components/CollectionBootleButton';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import EmailInput from './components/EmailInput';
import { useState } from 'react';
import { CheckValidFunc, FirstStepContext } from '../stepsContext';
// import GenderSelect from './components/GenderSelect';
import { useLocalNavigate } from 'src/router/routerTools';
import { useMemoizedFn } from 'ahooks';
import useCheckSampleId from '../hooks/useCheckSampleId';

export default function FirstStep() {
  const [t] = useTranslation();
  const navigate = useLocalNavigate();
  const collectionBottleId = useParams().collectionBottleId;
  const [hasClickedNext, setHasClickedNext] = useState(false);

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [checkValidFuncs, setCheckValidFuncs] = useState<CheckValidFunc[]>([]);
  const addCheckValidFunc = useMemoizedFn((func: CheckValidFunc) => {
    setCheckValidFuncs((funcs) => {
      return [...funcs, func];
    });

    return () => {
      setCheckValidFuncs((funcs) => {
        return funcs.filter((f) => f !== func);
      });
    };
  });
  const [loading, setLoading] = useState(false);

  const checkAllValid = async () => {
    setLoading(true);
    try {
      const results = await Promise.all(checkValidFuncs.map((func) => func()));
      return results.every((result) => result);
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleClickNext = async () => {
    setHasClickedNext(true);
    const isValid = await checkAllValid();
    if (isValid) {
      // 下一步
      navigate(`/2ndStep/${collectionBottleId}`);
    }
  };
  useCheckSampleId();
  return (
    <FirstStepContext.Provider
      value={{
        checkValidFuncs,
        addCheckValidFunc,
      }}
    >
      <div>
        <CollectionHeader />
        <StepBox step={1}>
          <div className="flex flex-col mb-[28px] lg:mb-[58px]">
            <div className="font-[500] text-[#2dbdb6] text-[18px] leading-[28px] lg:text-[22px] lg:leading-[28px]">
              {`${t('collectionBootle.yourSampleId')} :`}
            </div>
            <div
              className="font-[500] text-[#000000] text-[14px] leading-[28px]  lg:text-[18px] lg:leading-[38px]"
              style={{
                fontFamily: 'Montserrat',
              }}
            >
              {collectionBottleId}
            </div>
            <div className="mt-[8px] font-[500] text-[#25282B] text-[14px] leading-[28px] lg:mt-[15px] lg:text-[16px] lg:leading-[28px]">
              {t('collectionBootle.allRequiredDescription')}
            </div>
          </div>
          <CustomSteps curPage={CurPage.PersonalInfo} />
          <UserPhoneInput hasClickedNext={hasClickedNext} />
          <EmailInput hasClickedNext={hasClickedNext} />
          {/* <GenderSelect hasClickedNext={hasClickedNext} /> */}
          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[50px] mb-[0px] mt-[40px] md:mt-[40px] md:w-[170px] md:h-[50px] md:text-[16px] md:mb-[0px] md:leading-[24px]"
            value={t('collectionBootle.Next')}
            status="check"
            onClick={handleClickNext}
            loading={loading}
            sectionClassName={isLg ? 'flex justify-end' : undefined}
          ></CollectionBootleButton>
        </StepBox>
      </div>
    </FirstStepContext.Provider>
  );
}
