import { useTranslation } from 'react-i18next';
import { useGetFontStyle } from 'src/hooks/tools/useGetFontStyle';

export const useSteps = () => {
  const defaultFontStyle = useGetFontStyle();
  const [t] = useTranslation();

  return [
    {
      title: (
        <span
          style={{
            fontFamily: defaultFontStyle,
          }}
        >
          {t('collectionBootle.progress1')}
        </span>
      ),
      // description: 'This is a description.',
    },
    {
      title: (
        <span
          style={{
            fontFamily: defaultFontStyle,
          }}
        >
          {t('collectionBootle.Declaration')}
        </span>
      ),
      // description: 'This is a description.',
    },
    {
      title: (
        <span
          style={{
            fontFamily: defaultFontStyle,
          }}
        >
          {t('collectionBootle.progress3')}
        </span>
      ),
      // description: 'This is a description.',
    },
  ];
};
