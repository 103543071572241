import { useMemoizedFn } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FiledInput from './BaseComponent/FiledInput';
import { useAddCheckValidFunc, usePageContext } from './BaseComponent/formContext';
import { BasePageComponentProps, PageComponentConfigMap } from './BaseComponent/PageComponentMap';
import MessageText from '@pages/CollectionBootle/components/MessageText';

type FirstNameInputProps = BasePageComponentProps & PageComponentConfigMap['firstName'];

export default function LastNameInput(props: FirstNameInputProps) {
  const { pageValues, setPageValue } = usePageContext();
  const [isError, setIsError] = useState(false);

  const onValueChange = (v: string) => {
    setPageValue(props.title, v);
  };

  const value = pageValues[props.title]?.value;

  const [t] = useTranslation();
  const [firstName, setFirstName] = useState(value || '');

  const handleFirstNameChange = (v: string) => {
    setFirstName(v);
    onValueChange(v);
  };

  const checkValid = useMemoizedFn(() => {
    if (firstName) {
      return true;
    }
    setIsError(true);
    return false;
  });

  useAddCheckValidFunc(checkValid);

  return (
    <>
      <FiledInput
        value={firstName}
        onChange={handleFirstNameChange}
        title={t('collectionBootle.question2')}
        placeholder={t('collectionBootle.question2Placeholder')}
        filedInputClassName="mt-[29px] lg:mt-[29px]"
        forceUppercase={true}
        status={isError ? 'error' : undefined}
      />
      {isError && (
        <MessageText
          helpText={t('collectionBootle.fieldsRequiredMessage') as string}
          status={'error'}
          style={{
            marginTop: '5px',
          }}
          innerStyle={{
            justifyContent: 'flex-start',
            marginBottom: '0px',
          }}
        ></MessageText>
      )}
    </>
  );
}
