import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox';
import { MEDIA_SIZE } from '@consts/consts';
import FiledInput, { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import { useAddSecondStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';
import { useSetFormValue } from '@states/formValue.state';
import { useMemoizedFn } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';

export default function SecondAgreement(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  hasClickedNext;
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const setFormValue = useSetFormValue();

  const [undersignedBoxValue, setUndersignedBoxValue] = useState<boolean>(false);

  const [singedName, setSingedName] = useState<string>('');

  useAddSecondStepCheckValidFunc(() => {
    if (!undersignedBoxValue || !singedName) {
      return false;
    }
    return true;
  });

  const onCheckSignedValid = useMemoizedFn((val, { setHelpText, setStatus }, isFiledInputSubmitCheck) => {
    if (val === '' && !isFiledInputSubmitCheck) {
      setStatus(undefined);
      setHelpText('');
    } else if (val === '' && isFiledInputSubmitCheck) {
      setStatus('error');
      setHelpText(t('collectionBootle.FullNameEmptyMessage') as string);
    } else if (val.length >= 2) {
      setStatus('success');
      setHelpText('');
    } else {
      setStatus('error');
      setHelpText(t('collectionBootle.FullNameInvalidMessage') as string);
    }
  });
  return (
    <>
      <div
        className="flex cursor-pointer"
        style={{
          marginTop: isLg ? '29px' : '0.7073rem',
          marginBottom: isLg ? '0px' : '0rem',
        }}
        onClick={() => {
          setUndersignedBoxValue((pre) => !pre);
        }}
      >
        <BaseCheckbox
          style={{
            marginRight: isLg ? '16px' : '0.3902rem',
          }}
          checked={undersignedBoxValue}
        />
        <FiledTitle
          title={t('consentForm.v3_2ndAgreements') as string}
          style={{
            marginTop: isLg ? '0px' : '0px',
            marginBottom: isLg ? '0px' : '0px',
          }}
        />
      </div>
      <br />

      <FiledInput
        title={t('collectionBootle.question15')}
        placeholder={t('collectionBootle.question15Placeholder') as string}
        filedInputClassName="mt-[15px] lg:mt-[15px]"
        isFullName={false}
        onChange={(v) => {
          v = v.trim();
          setSingedName(v);
          setFormValue({
            18: v.toLowerCase(),
          });
        }}
        isSubmitCheck={hasClickedNext}
        onCheckValid={onCheckSignedValid}
        value={singedName}
      />
    </>
  );
}
