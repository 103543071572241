import { useTranslation } from 'react-i18next';
import StepTitle from './StepTitle';
import { useParams } from 'react-router-dom';
import CustomSteps from './CustomSteps';
import { useCheckLastStepDataValid, usePageContext } from '../BaseComponent/formContext';
import pageComponentMap from '../BaseComponent/PageComponentMap';
import CollectionBootleButton from '@pages/CollectionBootle/components/CollectionBootleButton';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useState } from 'react';
import { useIsZhHKLanguage } from 'src/hooks/tools/useIsZhHKLanguage';
import classNames from 'classnames';
import { useLocalNavigate } from 'src/router/routerTools';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import compaginApi from '../apis/compagin.api';
import { message } from 'antd';
import { clearLocalPageValues } from '../BaseComponent/LocalPageValues';

export function StepBox(props: { children?: React.ReactNode; step: number }) {
  const [t] = useTranslation();
  const { pageConfig, checkValidFuncs, pageValues, compaginName } = usePageContext();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [loading, setLoading] = useState(false);

  const { compaginId, collectionBottleId } = useParams();

  const navigate = useLocalNavigate();

  const goLastStep = () => {
    navigate(`/compagin/${compaginId}/${props.step}/${collectionBottleId}`);
  };

  const goNextStep = () => {
    navigate(`/compagin/${compaginId}/${props.step + 2}/${collectionBottleId}`);
  };
  const [isError, setIsError] = useState(false);

  const isLastStep = props.step === pageConfig.length - 1;
  const nextText = isLastStep ? t('collectionBootle.Submit') : t('collectionBootle.Next');

  const handleNextClick = async () => {
    const checkPromises = checkValidFuncs.map((func) => func());

    setLoading(true);
    try {
      const checkRes = await Promise.all(checkPromises);

      const finalRes = checkRes.every((item) => item);
      if (finalRes) {
        if (isLastStep) {
          const data = JSON.stringify(pageValues);
          setIsError(false);
          try {
            await compaginApi.addNewCompaginData({
              sampleId: collectionBottleId!,
              data,
              compaginName: compaginName,
              email: pageValues.Email?.value || '',
              phone: pageValues.Phone?.value || '',
            });
            clearLocalPageValues(collectionBottleId!);
            navigate(`/submitsuccess/${collectionBottleId}`);
          } catch (e: any) {
            if (e.response) {
              message.error(e.response.data.message);
            } else {
              message.error((e as any).message);
            }
          }
        } else {
          goNextStep();
        }
      } else {
        setIsError(true);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const isZh = useIsZhHKLanguage();
  useCheckLastStepDataValid();

  return (
    <div className="w-full h-full flex flex-col bg-[#f5f7fa]  min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] lg:w-[71.38vw] lg:m-auto  lg:bg-[#FFFFFF] lg:rounded-[8px] lg:shadow-[0_12px_26px_rgba(16,30,115,0.06)]">
      <StepTitle curPage={props.step} text={isZh ? pageConfig[props.step].nameCn : pageConfig[props.step].name} />

      <div className="p-[20px_25px_63px_25px] lg:p-[36px_9.86vw_64px_7.63vw]">
        <div className="flex flex-col mb-[28px] lg:mb-[58px]">
          <div className="font-[500] text-[#2dbdb6] text-[18px] leading-[28px] lg:text-[22px] lg:leading-[28px]">
            {`${t('collectionBootle.yourSampleId')} :`}
          </div>
          <div
            className="font-[500] text-[#000000] text-[14px] leading-[28px]  lg:text-[18px] lg:leading-[38px]"
            style={{
              fontFamily: 'Montserrat',
            }}
          >
            {collectionBottleId}
          </div>
          <div className="mt-[8px] font-[500] text-[#25282B] text-[14px] leading-[28px] lg:mt-[15px] lg:text-[16px] lg:leading-[28px]">
            {t('collectionBootle.allRequiredDescription')}
          </div>
        </div>
        <div className="relative">
          <div className="lg:w-[71.38vw] relative lg:left-[-7.63vw]">
            <CustomSteps curPage={props.step} />
          </div>
        </div>
        {pageConfig[props.step].components.map((item) => {
          const Component = pageComponentMap[item.type as 'email'];
          if (Component) {
            return <Component key={item.title} {...item} />;
          }
          return null;
        })}

        <div className={classNames('flex', props.step === 0 ? 'justify-end' : 'justify-between')}>
          {props.step !== 0 && (
            <CollectionBootleButton
              htmlType="submit"
              buttonClassName="w-full h-[50px] mb-[0px] mt-[40px] md:mt-[40px] md:w-[170px] md:h-[50px] md:text-[16px] md:mb-[0px] md:leading-[24px]"
              value={t('collectionBootle.Back')}
              status="check"
              onClick={goLastStep}
              sectionClassName={isLg ? 'flex justify-end' : undefined}
              loading={loading}
            ></CollectionBootleButton>
          )}

          <CollectionBootleButton
            htmlType="submit"
            buttonClassName="w-full h-[50px] mb-[0px] mt-[40px] md:mt-[40px] md:w-[170px] md:h-[50px] md:text-[16px] md:mb-[0px] md:leading-[24px]"
            value={nextText}
            status="check"
            onClick={handleNextClick}
            sectionClassName={isLg ? 'flex justify-end' : undefined}
            loading={loading}
          ></CollectionBootleButton>
        </div>
        {isError && (
          <MessageText
            helpText={t('collectionBootle.SubmitFailedText') || ''}
            status="error"
            style={{
              marginTop: isLg ? '11px' : '0.2683rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
        {props.children}
      </div>
    </div>
  );
}
