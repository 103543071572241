import { MEDIA_SIZE } from '@consts/consts';
import { FiledTitle } from '@pages/CollectionBootle/components/FiledInput';
import MessageText from '@pages/CollectionBootle/components/MessageText';
import { useAddThirdStepCheckValidFunc } from '@pages/CollectionBootle/stepsContext';
import { useSetFormValue, useGetFormValue } from '@states/formValue.state';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useEffect, useMemo, useState } from 'react';
import CnFiledInput, { CnFiledInputPropsOnCheckValidType } from '@pages/CollectionBootle/components/CnFiledInput';
import CollectionDatePicker from '@pages/CollectionBootle/components/CollectionDatePicker';
import { isLowVersionChangeDatePicker } from '@utils/index';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemoizedFn } from 'ahooks';
import { Select } from 'antd';
const isLowVersion = isLowVersionChangeDatePicker();
const timeOptionValue: {
  label: string;
  value: string;
}[] = [];

// 生成选项
for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute < 60; minute += 30) {
    const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    timeOptionValue.push({
      label: time,
      value: time,
    });
  }
}

export default function CollectionTime(props: { hasClickedNext: boolean }) {
  const hasClickedNext = props.hasClickedNext;
  const setFormValue = useSetFormValue();
  const formValue = useGetFormValue();
  const [t] = useTranslation();

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const [sampleCollectionDay, setSampleCollectionDay] = useState<string>('');
  const [sampleCollectionMonth, setSampleCollectionMonth] = useState<string>('');
  const [sampleCollectionYear, setSampleCollectionYear] = useState<string>('');

  const [sampleCollectionDate, setSampleCollectionDate] = useState<string | undefined>();

  const handleCheckSampleCollectionDay: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.DateNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckSampleCollectionMonth: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.MonthCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const handleCheckSampleCollectionYear: CnFiledInputPropsOnCheckValidType = useMemoizedFn(
    (val, { setStatus, setHelpText }, isFiledInputSubmitCheck) => {
      if (val === '' && !isFiledInputSubmitCheck) {
        setStatus(undefined);
        setHelpText('');
      } else if (val === '' && isFiledInputSubmitCheck) {
        setStatus('error');
        setHelpText(t('collectionBootle.YearCanNotBeEmpty') as string);
      } else {
        setStatus('success');
        setHelpText('');
      }
    },
  );

  const isError = !sampleCollectionDate && hasClickedNext;
  const isPickUp = formValue[19] === 'PickUp';

  const pickTime = formValue[21];
  const pickUpDate = formValue[20];
  const dropOffDate = formValue[-4];
  const limit24Hour = useMemo(() => {
    if (pickTime && pickTime.length > 0 && pickUpDate && pickUpDate.length > 0) {
      return {
        limitDate: pickUpDate,
        limitTime: pickTime.slice(6),
      };
    } else {
      return null;
    }
  }, [pickTime, pickUpDate]);

  useEffect(() => {
    if (!sampleCollectionDay && !sampleCollectionMonth && !sampleCollectionYear) {
      return;
    }

    if (sampleCollectionDay && sampleCollectionMonth && sampleCollectionYear) {
      setSampleCollectionDate(`${sampleCollectionYear}/${sampleCollectionMonth}/${sampleCollectionDay}`);
      setFormValue({
        24: `${sampleCollectionYear}/${sampleCollectionMonth}/${sampleCollectionDay}`,
      });
    } else {
      setSampleCollectionDate(undefined);
      setFormValue({
        24: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleCollectionDay, sampleCollectionMonth, sampleCollectionYear]);

  const [sampleCollectionTime, setSampleCollectionTime] = useState<string | undefined>();
  useEffect(() => {
    setSampleCollectionDate(undefined);
    setSampleCollectionTime(undefined);
    setFormValue({
      24: undefined,
      25: undefined,
    });
    setSampleCollectionDay('');
    setSampleCollectionMonth('');
    setSampleCollectionYear('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickTime, pickUpDate]);

  useAddThirdStepCheckValidFunc(() => {
    if (!sampleCollectionDate || !sampleCollectionTime) {
      return false;
    }
    return true;
  });
  const isTimeError = !sampleCollectionTime && hasClickedNext;

  return (
    <>
      <>
        {isLowVersion ? (
          <div className="flex flex-col mt-[29px] lg:mt-[45px]">
            {/* sampleCollectionLocationsTitleSerial */}
            <FiledTitle title={t('collectionBootle.question21')} />
            <div className="flex justify-between">
              <CnFiledInput
                style={{
                  width: '30%',
                  margin: isLg ? '0px' : '0px',
                }}
                value={sampleCollectionDay}
                onChange={setSampleCollectionDay}
                placeholder={t('collectionBootle.DatePlaceHolder')}
                onCheckValid={handleCheckSampleCollectionDay}
                isSubmitCheck={!hasClickedNext}
                number={true}
              />
              <CnFiledInput
                style={{
                  width: '30%',
                  margin: isLg ? '0px' : '0px',
                }}
                value={sampleCollectionMonth}
                onChange={setSampleCollectionMonth}
                placeholder={t('collectionBootle.MonthPlaceHolder')}
                onCheckValid={handleCheckSampleCollectionMonth}
                isSubmitCheck={!hasClickedNext}
                number={true}
              />
              <CnFiledInput
                style={{
                  width: '30%',
                  margin: isLg ? '0px' : '0px',
                }}
                value={sampleCollectionYear}
                onChange={setSampleCollectionYear}
                placeholder={t('collectionBootle.YearPlaceHolder')}
                onCheckValid={handleCheckSampleCollectionYear}
                isSubmitCheck={!hasClickedNext}
                number={true}
              />
            </div>
          </div>
        ) : (
          <CollectionDatePicker
            title={t('collectionBootle.question21') as string}
            onChange={(_, dateString) => {
              setSampleCollectionDate(dateString);
              setFormValue({
                24: dateString,
              });
            }}
            placeholder={t('collectionBootle.question6Placeholder') as string}
            defaultValue={sampleCollectionDate}
            value={sampleCollectionDate}
            forceDisabledDate={true}
            collectionDatePickerClassName={classNames(
              isError && 'my-select-selector-empty-type',
              'mt-[29px] lg:mt-[45px]',
            )}
            // TODO 需要做格式的校验
            disabledStartDate={
              isPickUp
                ? limit24Hour?.limitDate
                  ? dayjs(limit24Hour?.limitDate, 'YYYY/MM/DD').subtract(2, 'day').format('YYYY/MM/DD')
                  : undefined
                : dayjs(dropOffDate, 'YYYY/MM/DD').subtract(1).format('YYYY/MM/DD')
            }
            // 如果是pick up，需要限制不能超过取件日期
            disabledEndDate={
              isPickUp
                ? dayjs(limit24Hour?.limitDate, 'YYYY/MM/DD').format('YYYY/MM/DD')
                : dayjs(dropOffDate, 'YYYY/MM/DD').format('YYYY/MM/DD')
            }
          />
        )}

        {isError && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              marginTop: isLg ? '11px' : '0.2683rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </>

      <div
        className={classNames(
          isTimeError && 'my-select-selector-empty-type',
          'lg:w-[100%] m-auto w-[100%] flex flex-col mt-[29px] mb-[46px] lg:mt-[45px] lg:mb-[59px]',
        )}
      >
        <FiledTitle title={t('collectionBootle.question22')} />
        <Select
          onChange={(val) => {
            setSampleCollectionTime(val);
            setFormValue({
              25: val,
            });
          }}
          style={{
            height: isLg ? '48px' : '1.1707rem',
            color: '#8B849B',
            fontWeight: 400,
            fontSize: isLg ? '14px' : '0.3415rem',
            lineHeight: isLg ? '24px' : '0.5854rem',
          }}
          placeholder={t('collectionBootle.question22Placeholder') as string}
          defaultValue={sampleCollectionTime || undefined}
          value={sampleCollectionTime || undefined}
          // inputReadOnly={true}
          options={timeOptionValue}
        ></Select>

        {isTimeError && (
          <MessageText
            helpText={t('collectionBootle.fieldsRequiredMessage') as string}
            status={'error'}
            style={{
              marginTop: isLg ? '11px' : '0.2683rem',
            }}
            innerStyle={{
              justifyContent: 'flex-start',
              marginBottom: '0px',
            }}
          ></MessageText>
        )}
      </div>
    </>
  );
}
