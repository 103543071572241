export default function EnglishPart(props: { children: JSX.Element }) {
  return (
    <div>
      You are being asked to participate in a clinical study. Before you decide to be part of this clinical study,
      please read this form carefully and ensure that you understand the purpose, procedure, risks and benefits so that
      you can make an informed decision. If you have any doubts or questions, please talk to Phase Scientific. If you
      decide to participate in this clinical study, you will be asked to sign and submit this consent form. <br />
      <p className="underline">Personal Information Collection Statement</p>
      The provision of your personal data as contained in this form to Phase Scientific is voluntary. However, failure
      to provide your personal data may mean that you are not able to participate in this clinical study. Your personal
      data will be used only for the purpose of ascertaining your informed consent to participate in this clinical
      study. Your personal data will only be shared with Phase Scientific and will not be shared with any other third
      parties except with your express consent. Under the Hong Kong Personal Data (Privacy) Ordinance, you have the
      right to access your personal data and request for correction of your personal data if it is inaccurate. To submit
      a data access or correction request, please contact Phase Scientific’s customer service officer at 3700 8888 or by
      email at cs@indicaid.com. Aside from the personal data present on this form, Phase Scientific does not collect any
      other personal data from you in this clinical study.
      <br />
      <p className="underline">Purpose</p>
      The purpose of this clinical study is to validate a HPV Test with urine, and is not intended to provide you with
      any direct medical benefits by virtue of participating in this clinical study.
      <br />
      <p className="underline">Participant Selection</p>
      We invite everyone who are 18 years old or above to participate in this clinical study. (Selection Criteria)
      <br />
      <p className="underline">Organizer</p>
      Phase Scientific International Limited (Phase Scientific)
      <br />
      <p className="underline">Sampling Duration</p>
      ~10 minutes per collection round
      <br />
      <p className="underline">Sampling Location</p>
      Participant’s home 
      <br />
      <p className="underline">Sampling Procedure</p>
      1. 1.You will collect a first void urine sample and send the sample to Phase Scientific's laboratories to conduct
      HPV testing. Detailed sample collection procedure is in the urine collection kit
      <br />
      <p className="underline">Risk</p>
      1.Urine is a bodily fluid and is considered a biohazard, users are advised to wear gloves or wash their hands
      before and after collection. Use a biohazard bag to store the filled urine bottle. 2. Due to hygiene, users should
      avoid direct contact of the urine collection bottle with the genitals.
      <br />
      <p className="underline">Confidentiality</p>
      As part of the clinical analysis, your samples and/or test results will be combined with that of other
      participants in the clinical study, and you will be identifiable only by a number (not your name)
      <br />
      <p className="underline">Incentive</p>
      A HPV test report
      <br />
      <p className="underline">Termination</p>
      Your participation in this clinical study may be terminated at any time by Phase Scientific if:
      <br />
      • You are unable to meet the Selection Criteria; or
      <br />• For any reason the study investigator believes it is in your best interest.
      <p className="underline">Questions</p>
      If you have any questions about this clinical study, please contact Phase Scientific directly at cs@indicaid.com.
      <br />
      <p className="underline">Marketing</p>
      Phase Scientific intends to use your contact details (including your email address, contact phone number and
      address (if provided)) for the purpose of sending to you direct marketing materials relating to the products and/
      or services of Phase Scientific and to invite you to participate in the Research, and we require your consent to
      do so. The classes of products and services that may be marketed are health and wellness related products and
      services. We also intend to provide the data described above to our affiliates in order to enable them to direct
      market to you other health and wellness related products and services, and we require your written consent in
      order to do so. <br />
      <br />
      If, having given your consent, you no longer wish for us to use or provide to other persons your personal data for
      use in direct marketing as described above, you may withdraw your consent by contacting Phase Scientific's
      customer service team at 3700 8888 or email cs@indicaid.com or through other channels as Phase Scientific may
      announce from time to time, at no cost to you.
      <br />
      <br />
      <div className="flex">
        {props.children}
        <div>
          {' '}
          I disagree with the proposed use and provision of my personal data for direct marketing purposes as stated in
          paragraph under “Marketing” above.
        </div>
      </div>
      <br />
      <p className="underline">PARTICIPANT’S STATEMENT</p>
      By agreeing to this form, I confirm that：
      <br />
      <br /> 1.I have read and understand this form and its contents were explained to me. I have been given a chance to
      ask questions about this clinical study and my questions (if any) have been answered to my satisfaction. I
      understand that I may contact Phase Scientific if I have any more questions about taking part in this clinical
      study, before agreeing to this informed consent.
      <br />
      2.I understand that my participation in this clinical study is voluntary. I understand that once my urine specimen
      has been provided to Phase Scientific, I will not be able to withdraw my specimen from the study.
      <br />
      3.I understand no funds have been set aside if I require medical treatment as a result of injury arising from my
      participation in this clinical study. The financial responsibility for such care will be mine.
      <br />
      4.I understand I will not be reimbursed or be benefited in any way.
      <br />
      5.My samples and/or test results may be stored by Phase Scientific and used later for research and development,
      and I will not have any rights to or interest in products which may come from my provision of the samples and/or
      test results. No funds have been set aside for additional compensation.
      <br />
      6.By agreeing to this form, I have not waived any of my legal rights.
    </div>
  );
}
